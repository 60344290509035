<template>
  <div
    tabindex="0"
    @keydown.esc.stop="blur"
    @keydown.delete="handleKeyPressDelete"
    @keydown.up="handleKeyPressUp"
    @keydown.down="handleKeyPressDown"
    @keydown.enter="handleKeyPressEnter"
  >
    <SelectInputQuery
      v-model="query"
      :input-class="inputClass"
      :multiple="multiple"
      :placeholder="inputPlaceholder"
      :selected-items="iterableSelectedItems"
      :should-filter="shouldFilter"
      @remove-item="handleRemoveItem"
    />
    <div :class="$style.selectInputDivider" />
    <span v-if="loading" class="text-gray-60 py-8 text-sm font-normal flex justify-center">
      <app-spinner size="4"></app-spinner>
    </span>
    <ul v-else-if="filteredItems.length || shouldShowCreate" :class="$style.itemSelectorList">
      <SelectInputOptionList
        v-if="filteredItems.length > 0"
        :hovered="hovered"
        :item-class="$style.itemSelectorListItem"
        :item-hovered-class="$style.itemSelectorListItem__hover"
        :items="filteredItems"
        :multiple-entities="multipleEntities"
        @hovered="handleHoverItem"
        @selected="handleSelectItem"
      >
        <template #option="{ item: itemFromSlot, isHovered }">
          <slot :is-hovered="isHovered" :item="itemFromSlot" name="option"></slot>
        </template>
      </SelectInputOptionList>
      <SelectInputOptionCreator
        v-show="shouldShowCreate"
        :class="[
          $style.itemSelectorListItem,
          { [$style.itemSelectorListItem__hover]: 'new' === hovered }
        ]"
        :create-fn="createFn"
        :query="query"
        @created="handleCreateItem"
        @hovered="handleHoverItem"
      />
    </ul>
    <span v-else class="text-gray-60 p-4 block text-sm font-normal">
      {{ emptyPlaceholder }}
    </span>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import SelectInputOptionCreator from '@/shared/components/Form/SelectInput/SelectInputOptionCreator.vue'
import SelectInputQuery from '@/shared/components/Form/SelectInput/SelectInputQuery.vue'
import SelectInputOptionList from '@/shared/components/Form/SelectInput/SelectInputOptionList.vue'

const props = defineProps({
  items: {
    type: Array,
    default: () => []
  },
  loading: {
    type: Boolean,
    default: false
  },
  inline: {
    type: Boolean,
    default: false
  },
  multiple: {
    type: Boolean,
    default: false
  },
  multipleEntities: {
    type: Boolean,
    default: false
  },
  canCreate: {
    type: Boolean,
    default: false
  },
  createFn: {
    type: Function,
    default: () => {}
  },
  inputPlaceholder: {
    type: String,
    default: 'Type something...'
  },
  inputClass: {
    type: String,
    default: ''
  },
  emptyPlaceholder: {
    type: String,
    default: 'No results found'
  }
})

const emit = defineEmits(['query-change', 'blur'])

const model = defineModel({
  type: [Array, Object, null],
  required: true
})
const query = ref('')
const hovered = ref(null)

const iterableSelectedItems = computed(() => {
  if (Array.isArray(model.value)) {
    return model.value
  } else if (model.value) {
    return [model.value]
  } else {
    return []
  }
})

const filteredItems = computed(() => {
  return props.items
    .filter((t) => {
      if (props.multiple) {
        return !model.value.map((t) => t.id).includes(t.id)
      } else {
        return model.value?.id !== t.id
      }
    })
    .filter((t) => t.name.toLowerCase().includes(query.value.toLowerCase()))
})

const shouldShowCreate = computed(() => {
  return (
    props.canCreate &&
    filteredItems.value.length !== 1 &&
    query.value &&
    query.value !== '' &&
    !props.items.map((i) => i.name.toLowerCase()).includes(query.value.toLowerCase())
  )
})

const shouldFilter = computed(() => {
  return props.multiple || model.value === null
})

watch(query, (newValue) => {
  emit('query-change', newValue)
})

const blur = async () => {
  emit('blur')
}
const handleRemoveItem = (item) => {
  if (props.multiple) {
    const index = model.value.findIndex((i) => i.id === item.id)
    model.value.splice(index, 1)
  } else {
    model.value = null
  }
}
const handleSelectItem = (item) => {
  if (props.multiple) {
    model.value.push({ id: item.id, name: item.name, color: item.color })
  } else {
    model.value = item
    blur()
  }
}
const handleCreateItem = (item) => {
  if (props.multiple) {
    model.value.push(item)
  } else {
    model.value = item
  }
  query.value = ''
}
const handleHoverItem = (item) => {
  hovered.value = item
}

const handleKeyPressUp = () => {
  if (filteredItems.value.length === 0) {
    return
  }

  if (hovered.value && hovered.value !== 'new') {
    const index = filteredItems.value.findIndex((i) => i.id === hovered.value)
    hovered.value = index > 0 ? filteredItems.value[index - 1].id : filteredItems.value[index].id
  }

  document
    .querySelector('#option-' + hovered.value)
    .scrollIntoView({ behaviour: 'smooth', block: 'end' })
}

const handleKeyPressDown = () => {
  if (filteredItems.value.length === 0) {
    if (shouldShowCreate.value) {
      hovered.value = 'new'
    }
    return
  }

  if (!hovered.value) {
    hovered.value = filteredItems.value[0].id
  } else if (hovered.value !== 'new') {
    const index = filteredItems.value.findIndex((i) => i.id === hovered.value)
    if (index === filteredItems.value.length - 1) {
      hovered.value = shouldShowCreate.value ? 'new' : filteredItems.value[index].id
    } else {
      hovered.value = filteredItems.value[index + 1].id
    }
  }

  document
    .querySelector('#option-' + hovered.value)
    .scrollIntoView({ behaviour: 'smooth', block: 'end' })
}

const handleKeyPressEnter = () => {
  if (filteredItems.value.length === 0 || !hovered.value) {
    return
  }

  const item = filteredItems.value.find((i) => i.id === hovered.value)
  handleCreateItem(item)
  hovered.value = null
}

const handleKeyPressDelete = (event) => {
  if (event.key === 'Backspace' && query.value.length === 0) {
    if (props.multiple) {
      model.value.pop()
    } else {
      model.value = null
    }
  }
}
</script>

<style lang="scss" module>
.selectInput {
  @apply cursor-pointer py-1.5 px-3 rounded-lg transition-colors inline-flex overflow-hidden items-center w-full max-w-2xs;

  &:hover {
    @apply bg-purple-10;
  }

  & > * {
    @apply basis-full;
  }
}

.selectInputDivider {
  @apply border-b border-gray-10;
}

ul.itemSelectorList {
  @apply max-h-56 overflow-auto p-2;

  li.itemSelectorListItem {
    @apply text-sm text-gray-100 py-1 px-2 min-h-8 flex items-center cursor-pointer rounded-md relative;
    span {
      @apply truncate block;
    }

    &.itemSelectorListItem__hover {
      @apply bg-purple-10;
    }
  }
}
</style>
