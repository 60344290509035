import useTenantStore from '@/modules/tenant/store'

export default {
  currentTenant: (state) => {
    if (!state.currentTenantId) return null
    
    const tenantStore = useTenantStore()
    return tenantStore.find(state.currentTenantId)
  }
}
