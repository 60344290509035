import authAxios from '@/shared/axios/auth-axios'

export default class DealService {
  static async update(id, data) {
    const response = await authAxios.put(`/deal/${id}`, data)

    return response.data
  }

  static async destroy(id) {
    await authAxios.delete(`/deal/${id}`)

    return true
  }

  static async create(data) {
    const response = await authAxios.post(`/deal`, data)

    return response.data
  }

  static async find(id) {
    const response = await authAxios.get(`/deal/${id}`)
    return response.data
  }

  static async list(
    query = undefined,
    filter = undefined,
    sort = undefined,
    perPage = undefined,
    page = undefined
  ) {
    const params = {
      query,
      filter,
      sort,
      perPage,
      page
    }

    const response = await authAxios.get(`/deal`, { params })

    return response.data
  }
}
