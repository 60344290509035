<template>
  <li @mouseover="$emit('hovered', item)">
    <slot :item="item" name="option">
      <div class="flex items-center w-full">
        <img v-if="item.icon" :src="item.icon" class="h-4 mr-2" />
        <span>{{ item.name }}</span>
        <Popover
          ref="secondPanelRef"
          class="popover popover__calendar !-mt-10 !ml-64"
          @hide="expanded = false"
          @show="expanded = true"
        >
          <ul :class="$style.itemSelectorList">
            <TreeSelectInputOption
              v-for="option of computedOptions"
              :key="option.id"
              :class="[
                $style.itemSelectorListItem,
                { [$style.itemSelectorListItem__hover]: option.id === hovered },
                {
                  [$style.itemSelectorListItem__active]: option.id === modelValue
                }
              ]"
              :hovered="option.id === hovered"
              :item="option"
              @hovered="handleHoverItem"
              @selected="handleSelectItem"
            />
          </ul>
        </Popover>
      </div>
    </slot>
  </li>
</template>
<script setup>
import Popover from 'primevue/popover'
import { computed, ref, watch } from 'vue'
import TreeSelectInputOption from '@/shared/components/Form/TreeSelectInput/TreeSelectInputOption.vue'

const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  },
  hovered: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: [Array, Object, null],
    default: null
  }
})

const secondPanelRef = ref()
const expanded = ref(false)
const hovered = ref(null)

const emit = defineEmits(['selected', 'hovered'])

const computedOptions = computed(() => {
  return props.item.options.map((o) => {
    return {
      ...o,
      group: props.item.id
    }
  })
})

const isHovered = computed(() => props.hovered)
watch(isHovered, (value) => {
  if (value) {
    secondPanelRef.value.show(event)
  } else {
    secondPanelRef.value.hide(event)
  }
})

const handleSelectItem = (item) => {
  emit('selected', item)
}

const handleHoverItem = (item) => {
  hovered.value = item.id
}
</script>

<style lang="scss" module>
ul.itemSelectorList {
  @apply max-h-56 overflow-auto p-2;

  li.itemSelectorListItem {
    @apply text-sm text-gray-100 px-2 h-8 flex items-center cursor-pointer rounded-md;
    &.itemSelectorListItem__hover {
      @apply bg-purple-10;
    }

    &.itemSelectorListItem__active {
      @apply text-purple-100;
    }
  }
}
</style>
