<template>
  <div :style="computedStyle" class="tag">
    {{ tag.name }}
    <button v-if="removable" class="tag-remove" @click="$emit('remove', tag)">
      <app-icon :color="`${computedColor}-100`" name="close-xs"></app-icon>
    </button>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '@/../tailwind.config.js'

const { theme } = resolveConfig(tailwindConfig)

const props = defineProps({
  tag: {
    type: Object,
    default: () => {}
  },
  removable: {
    type: Boolean,
    default: false
  }
})
defineEmits(['remove'])

const computedColor = computed(() => props.tag.color)

const computedStyle = computed(() => {
  return computedColor.value
    ? {
        backgroundColor: theme.colors[computedColor.value][10],
        borderColor: theme.colors[computedColor.value][100],
        color: theme.colors[computedColor.value][100]
      }
    : {
        backgroundColor: theme.colors.gray[10],
        borderColor: theme.colors.gray[20],
        color: theme.colors.gray[80]
      }
})
</script>
