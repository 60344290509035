import sharedGetters from '@/shared/store/getters'
import useAuthStore from '@/modules/auth/store'

export default {
  ...sharedGetters(),
  entityTasks: (state) => (entityId, entityType) => {
    return Object.values(state.records).filter((record) => {
      return record.taskable_id === entityId && record.taskable_type === entityType
    })
  },
  currentUserTasks: (state) => {
    const authStore = useAuthStore()
    return Object.values(state.records).filter((record) =>
      record.users.some((r) => r.id === authStore.currentUser.id)
    )
  }
}
