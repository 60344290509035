<template>
  <div>
    <slot :on-click="handleToggle" name="trigger">
      <button
        :class="{ btn__active: Object.keys(model.attributes).length > 0 }"
        class="btn btn--secondary ml-2"
        @click="handleToggle"
      >
        <app-icon class="stroke-gray-100" name="filter" />
        Filters
      </button>
    </slot>
    <TieredMenu ref="menu" :model="computedItems" popup @hide="$emit('hide')" @show="$emit('show')">
      <template #item="{ item }">
        <div class="cursor-pointer p-2">{{ item.label }}</div>
      </template>
    </TieredMenu>
  </div>
</template>
<script setup>
import TieredMenu from 'primevue/tieredmenu'
import { computed, ref } from 'vue'

const props = defineProps({
  items: {
    type: Array,
    default: () => []
  }
})

defineEmits(['show', 'hide'])

const model = defineModel({
  type: Object,
  required: true
})

const menu = ref()

const computedItems = computed(() => {
  return props.items.map((i) => {
    const filterable = i.forFilter()
    return {
      ...filterable,
      command: () => handleAddFilterAttribute(filterable)
    }
  })
})

const handleAddFilterAttribute = (attr) => {
  model.value.attributes[attr.name] = {
    operator: attr.operator,
    defaultOperator: attr.defaultOperator,
    value: attr.value,
    defaultValue: attr.defaultValue,
    availableOperators: attr.availableOperators,
    query: null,
    expanded: true
  }
}

const handleToggle = (event) => {
  menu.value.toggle(event)
}
</script>
