<template>
  <div :class="$style.emptyState">
    <div :class="$style.emptyStateBg"></div>
    <div :class="$style.emptyStateContent">
      <div v-if="emoji" :class="$style.emptyStateEmoji">
        {{ emoji }}
      </div>
      <div :class="$style.emptyStateTitle">
        {{ title }}
      </div>
      <div :class="$style.emptyStateDescription" v-html="description"></div>
      <slot name="new-record">
        <button v-if="createBtn" class="btn btn--primary mt-4" @click="emit('new-record')">
          <app-icon v-if="createBtnIcon" :name="createBtnIcon" />
          {{ createBtnLabel }}
        </button>
      </slot>
    </div>
  </div>
</template>
<script setup>
defineProps({
  emoji: {
    type: String,
    default: null
  },
  title: {
    type: String,
    required: true
  },
  description: {
    type: String,
    required: true
  },
  createBtn: {
    type: Boolean,
    default: false
  },
  createBtnLabel: {
    type: String,
    default: 'New'
  },
  createBtnIcon: {
    type: String,
    default: 'plus'
  }
})

const emit = defineEmits(['new-record'])
</script>

<style lang="scss" module>
.emptyState {
  @apply absolute inset-0;
}

.emptyStateBg {
  background: linear-gradient(180deg, #ffffff 0%, #fafafa 100%);
  @apply blur-sm z-10 absolute inset-0;
}

.emptyStateContent {
  @apply flex flex-col items-center justify-center z-20 absolute inset-x-0 h-5/6;
}

.emptyStateEmoji {
  @apply text-3xl mb-4;
}

.emptyStateTitle {
  @apply font-medium mb-2;
}

.emptyStateDescription {
  @apply text-sm font-normal text-center;
}
</style>
