export default () => {
  return {
    records: {},
    list: {
      ids: [],
      loading: false,
      table: false,
    },
    count: 0
  }
}
