<template>
  <div class="w-full bg-white border border-gray-15 rounded-xl shadow-lg p-4">
    <button class="flex items-center w-full justify-between" @click="model = !model">
      <p class="text-sm font-medium">
        <slot name="header"></slot>
      </p>
      <app-icon :class="{ 'rotate-180': model }" :name="'chevron-down'" />
    </button>
    <transition name="fade">
      <div v-if="model" class="text-sm pt-4">
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script setup>
const model = defineModel({ type: Boolean, default: true })
</script>
