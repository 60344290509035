<template>
  <div :class="$style.boardColumn">
    <div v-if="loading">
      <div v-for="item of Array(4)" :key="item" class="bg-white rounded-md p-4 mb-3">
        <Skeleton class="mb-4" width="100%"></Skeleton>
        <Skeleton width="80%"></Skeleton>
      </div>
    </div>
    <div v-else class="flex flex-col max-h-full basis-full">
      <ViewTypeBoardColumnHeader :column="column" :show-sum="showSum" />
      <transition-group>
        <draggable
          :key="module"
          v-model="items"
          :class="$style.boardColumnScroll"
          :data-column-id="column.id"
          item-key="id"
          v-bind="dragOptions"
          @end="handleDragEnd"
          @mouseenter="isHovered = true"
          @mouseleave="isHovered = false"
          @start="handleDragStart"
        >
          <template #item="{ element }">
            <ViewTypeBoardColumnItem
              :item="element"
              class="mb-3"
              @click.prevent="$emit('view-record', element.id)"
            />
          </template>
          <template #footer>
            <transition name="fade">
              <div v-if="isHovered">
                <button
                  class="btn bg-gray-10 hover:bg-gray-15 w-full"
                  @click="$emit('new-record', { status: column.id })"
                >
                  <app-icon name="plus" />
                </button>
              </div>
            </transition>
          </template>
        </draggable>
      </transition-group>
    </div>
  </div>
</template>

<script setup>
import draggable from 'vuedraggable'
import { ref, shallowRef } from 'vue'
import Errors from '@/shared/error/errors'
import Skeleton from 'primevue/skeleton'
import ViewTypeBoardColumnHeader from '@/modules/view/components/type/board/ViewTypeBoardColumnHeader.vue'
import ViewTypeBoardColumnItem from '@/modules/view/components/type/board/ViewTypeBoardColumnItem.vue'
import { cloneDeep } from 'lodash'

defineEmits(['view-record', 'new-record'])

const props = defineProps({
  module: {
    type: String,
    default: ''
  },
  column: {
    type: Object,
    default: () => {}
  },
  loading: {
    type: Boolean,
    default: false
  },
  showSum: {
    type: Boolean,
    default: false
  },
  updateFn: {
    type: Function,
    default: () => {}
  }
})

const items = shallowRef(
  cloneDeep(props.column.items).sort((a, b) => {
    if (a.position < b.position) {
      return -1
    } else if (a.position > b.position) {
      return 1
    }
    return 0
  })
)

const isHovered = ref(false)
const drag = ref(false)
const dragOptions = ref({
  animation: 200,
  group: `board_${props.module}`,
  disabled: false,
  ghostClass: 'ghost'
})

const handleDragStart = (event) => {
  const item = event.item._underlying_vm_
  const { from } = event
  drag.value = true
  item.oldColumn = from.dataset.columnId
}

const handleDragEnd = async (event) => {
  const { item: element, newIndex, oldIndex, to } = event
  const { _underlying_vm_: item } = element
  item.newColumn = to.dataset.columnId

  if (
    item.newColumn !== item.oldColumn ||
    (item.newColumn === item.oldColumn && newIndex !== oldIndex)
  ) {
    try {
      await props.updateFn(item.id, { position: newIndex, status: item.newColumn })
    } catch (e) {
      Errors.handle(e)
      items.value.find((v) => v.id === item.id).position = oldIndex
    }
  }
  item.drag = false
  drag.value = false
}
</script>

<style lang="scss" module>
.boardColumn {
  @apply flex flex-col bg-gray-5 w-full max-w-xs shrink-0 mx-2.5 p-4 rounded-lg;
}

.boardColumnScroll {
  @apply max-h-full overflow-auto basis-full;
}
</style>
