import useIntegrationStore from '@/modules/integration/store'
import useTagStore from '@/modules/tag/store'
import useNotificationStore from '@/modules/notification/store'
import useBrandStore from '@/modules/brand/store'
import useUserStore from '@/modules/user/store'
import useViewStore from '@/modules/view/store'
import useTaskStore from '@/modules/task/store'

export default async function ({ to }) {
  if (!to.meta || !to.meta.init) {
    return
  }

  const integrationStore = useIntegrationStore()
  const tagStore = useTagStore()
  const notificationStore = useNotificationStore()
  const brandStore = useBrandStore()
  const userStore = useUserStore()
  const viewStore = useViewStore()
  const taskStore = useTaskStore()

  const promises = []

  if (!integrationStore.hasRows) {
    promises.push(integrationStore.doFetch())
  }

  if (!tagStore.hasRows) {
    promises.push(tagStore.doFetch())
  }

  if (!notificationStore.hasRows) {
    promises.push(notificationStore.doFetch())
  }

  if (!taskStore.hasRows) {
    promises.push(taskStore.doFetch())
  }

  if (!brandStore.hasRows) {
    promises.push(brandStore.doFetch())
  }

  if (!userStore.hasRows) {
    promises.push(userStore.doFetch())
  }

  if (!viewStore.hasRows) {
    promises.push(viewStore.doFetch())
  }

  await Promise.all(promises)
}
