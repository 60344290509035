<template>
  <div class="relative ml-2">
    <app-icon class="stroke-gray-60 absolute left-3 top-2/4 -mt-2" name="search" />
    <InputText v-model="computedModel" class="!pl-8" clearable placeholder="Search" />
    <app-icon
      v-if="computedModel"
      class="absolute right-3 top-2/4 -mt-1.5 stroke-gray-60 hover:stroke-gray-80 cursor-pointer h-3 w-3"
      name="close"
      @click="model = null"
    ></app-icon>
  </div>
</template>

<script setup>
import InputText from 'primevue/inputtext'
import { computed } from 'vue'
import debounce from 'lodash/debounce'

const model = defineModel({
  type: String,
  default: null
})

const computedModel = computed({
  get() {
    return model.value
  },
  set: debounce((v) => {
    model.value = v
  }, 200)
})
</script>
