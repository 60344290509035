export default [
  {
    name: 'company-list',
    path: 'companies',
    component: () => import('@/modules/company/pages/CompanyListPage.vue'),
    meta: { auth: true, emailVerified: true, tenant: true, init: true }
  },
  {
    name: 'company-view',
    path: 'companies/:id',
    component: () => import('@/modules/company/pages/CompanyViewPage.vue'),
    meta: { auth: true, emailVerified: true, tenant: true, init: true },
    props: true
  }
]
