import authAxios from '@/shared/axios/auth-axios'
import AuthCurrentTenant from '@/modules/auth/auth-current-tenant'

export default class TenantService {
  static async fetchAndApply() {
    let currentTenant

    const tenantId = AuthCurrentTenant.get()
    if (tenantId) {
      try {
        currentTenant = await this.find(tenantId)
        AuthCurrentTenant.set(tenantId)
      } catch (error) {
        console.error(error)
      }
    }

    return currentTenant
  }

  static async update(id, data, isFormData = false) {
    // https://stackoverflow.com/questions/74471540/multipart-form-data-not-working-on-axios-put-request

    const response = isFormData
      ? await authAxios.post(`/tenant/${id}`, data)
      : await authAxios.put(`/tenant/${id}`, data)

    return response.data
  }

  static async destroy(ids) {
    const params = {
      ids
    }

    const response = await authAxios.delete('/tenant', {
      params
    })

    return response.data
  }

  static async create(data) {
    const response = await authAxios.post('/tenant', data)

    return response.data
  }

  static async acceptInvitation(token, forceAcceptOtherEmail = false) {
    const response = await authAxios.post(`/tenant/invitation/${token}/accept`, {
      forceAcceptOtherEmail
    })

    return response.data
  }

  static async declineInvitation(token) {
    const params = null

    const response = await authAxios.delete(`/tenant/invitation/${token}/decline`, {
      params
    })

    return response.data
  }

  static async find(id) {
    const response = await authAxios.get(`/tenant/${id}`)
    return response.data
  }

  static async list(
    query = undefined,
    filter = undefined,
    sort = undefined,
    perPage = undefined,
    page = undefined
  ) {
    const params = {
      query,
      filter,
      sort,
      perPage,
      page
    }

    const response = await authAxios.get('/tenant', {
      params
    })

    return response.data
  }

  static async populateSampleData(tenantId) {
    const response = await authAxios.post(`/tenant/${tenantId}/sampleData`)

    return response.data
  }
}
