<template>
  <div>
    <button
      v-tooltip.top="tooltip"
      :class="buttonClass ?? 'btn btn--shadow !h-6 !px-0 !w-6 !rounded'"
      aria-controls="overlay_tmenu"
      aria-haspopup="true"
      type="button"
      @click.stop="handleToggle"
    >
      <span v-if="buttonLabel">{{ buttonLabel }}</span>
      <app-icon v-if="buttonIcon" :class="buttonIconClass ?? '!mx-0'" :name="buttonIcon" />
    </button>
    <TieredMenu
      :id="`options-menu-${id}`"
      ref="menu"
      :model="computedItems"
      popup
      @hide="$emit('hide')"
      @show="$emit('show')"
    >
      <template #item="{ item }">
        <div v-if="item.type === 'action'" class="flex items-center cursor-pointer p-2">
          <app-icon
            v-if="item.icon"
            :color="item.action === 'delete' ? 'destructive-100' : 'gray-80'"
            :name="item.icon"
            class="mr-2 h-4 w-4"
          ></app-icon>
          <span class="text-gray-100">{{ item.label }}</span>
        </div>
        <hr v-else-if="item.type === 'separator'" class="border-gray-15 my-2" />
      </template>
    </TieredMenu>
  </div>
</template>
<script setup>
import TieredMenu from 'primevue/tieredmenu'
import { computed, ref } from 'vue'
import { useConfirm } from 'primevue/useconfirm'

const props = defineProps({
  id: {
    type: String,
    default: undefined
  },
  items: {
    type: Array,
    default: () => []
  },
  buttonClass: {
    type: String,
    default: null
  },
  buttonLabel: {
    type: String,
    default: null
  },
  buttonIcon: {
    type: String,
    default: 'dot-vertical'
  },
  buttonIconClass: {
    type: String,
    default: null
  },
  tooltip: {
    type: String,
    default: 'More actions'
  }
})

const emit = defineEmits(['click-item', 'show', 'hide'])

const menu = ref()

const handleToggle = (event) => {
  menu.value.toggle(event)
}

const confirm = useConfirm()

const computedItems = computed(() => {
  return props.items.map((i) => {
    return {
      ...i,
      command: () => {
        if (!i.confirmed) {
          emit('click-item', { id: props.id, ...i })
        } else {
          confirm.require({
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation',
            rejectLabel: 'Cancel',
            acceptLabel: 'Yes, delete',
            rejectClass: 'btn btn--gray',
            acceptClass: 'btn btn--delete',
            accept: () => {
              emit('click-item', { action: i.action, id: props.id })
            }
          })
        }
      }
    }
  })
})
</script>
