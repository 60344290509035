import useAuthStore from '@/modules/auth/store'

export default async function () {
  const authStore = useAuthStore()

  if (authStore.csrfCookieFetched) {
    return
  }

  await authStore.doFetchCsrfToken()
}
