<template>
  <div>
    <InputNumber
      v-if="operator !== '$between'"
      v-model="computedValue"
      :currency="mode === 'currency' ? 'EUR' : undefined"
      :mode="mode"
      class="mt-1"
      locale="en-US"
      size="sm"
    />
    <div v-else class="flex items-end gap-2 pt-1">
      <div>
        <div class="text-xs text-gray-40">From</div>
        <InputNumber
          v-model="arrayValue[0]"
          :currency="currency"
          :mode="mode"
          class="mt-1"
          size="sm"
        />
      </div>
      <div>
        <div class="text-xs text-gray-40">To</div>
        <InputNumber
          v-model="arrayValue[1]"
          :currency="currency"
          :mode="mode"
          class="mt-1"
          size="sm"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import InputNumber from 'primevue/inputnumber'
import { computed, ref, watch } from 'vue'
import { cloneDeep, isArray } from 'lodash'

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  operator: {
    type: String,
    required: true
  },
  mode: {
    type: String,
    default: 'decimal'
  },
  currency: {
    type: String,
    default: null
  }
})

const model = defineModel({
  type: [Number, Array, null],
  required: true
})

const arrayValue = ref(isArray(model.value) ? model.value : [])

const computedValue = computed({
  get() {
    return model.value
  },
  set(v) {
    model.value = v
  }
})

watch(
  () => props.operator,
  () => {
    if (props.operator === '$between') {
      arrayValue.value[0] = cloneDeep(model.value)
    } else if (arrayValue.value[0]) {
      model.value = cloneDeep(arrayValue.value[0])
    }
  }
)

watch(
  arrayValue,
  (newValue) => {
    if (newValue[0] && newValue[1]) {
      model.value = newValue
    }
  },
  { deep: true }
)
</script>
