import authAxios from '@/shared/axios/auth-axios'

export default class ViewService {
  static async update(id, data) {
    const response = await authAxios.put(`/view/${id}`, data)

    return response.data
  }

  static async destroy(id) {
    await authAxios.delete(`/view/${id}`)

    return true
  }

  static async create(data) {
    const response = await authAxios.post(`/view`, data)

    return response.data
  }

  static async find(id) {
    const response = await authAxios.get(`/view/${id}`)
    return response.data
  }

  static async list(filter, sort, perPage, page) {
    const params = {
      filter,
      sort,
      perPage,
      page
    }

    const response = await authAxios.get(`/view`, {
      params
    })

    return response.data
  }
}
