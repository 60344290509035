<template>
  <transition name="fade">
    <div
      v-if="model && model.length"
      class="absolute inset-x-0 mx-auto bottom-4 flex justify-center z-100"
      style="max-width: 15rem"
    >
      <div
        class="bg-white rounded-2xl shadow-md border border-gray-15 px-4 py-2 text-sm flex items-center justify-between w-full"
      >
        <div class="flex items-center">
          <span class="block font-regular text-gray-100">{{ model.length }} selected</span>
          <span class="block mx-2 text-gray-15">|</span>
          <button class="btn btn--secondary btn--sm" @click="handleBulkDelete">
            <app-icon class="!stroke-destructive-100" name="trash" />
            Delete
          </button>
        </div>
        <button class="btn btn--shadow !h-5 !px-0 !w-5 !rounded" @click="model = []">
          <app-icon class="!mx-0 h-4" color="gray-60" name="close"></app-icon>
        </button>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { useConfirm } from 'primevue/useconfirm'

const confirm = useConfirm()

const props = defineProps({
  deleteFn: {
    type: Function,
    default: () => {}
  }
})

const model = defineModel({ required: true })

const handleBulkDelete = () => {
  confirm.require({
    message: 'Are you sure you want to proceed?',
    header: 'Confirmation',
    rejectLabel: 'Cancel',
    acceptLabel: 'Yes, delete',
    rejectClass: 'btn btn--gray',
    acceptClass: 'btn btn--delete',
    accept: async () => {
      for (const record of model.value) {
        await props.deleteFn(record.id)
      }
    }
  })
}
</script>
