import * as yup from 'yup'
import isInteger from 'lodash/isInteger'
import GenericField from '@/shared/fields/generic-field'
import { i18nTranslate } from '@/i18n'
import FilterOperators from '@/enums/FilterOperators'

export default class NumberField extends GenericField {
  constructor(name, label, config = {}) {
    super(name, label, config)

    this.required = config.required
    this.min = config.min
    this.max = config.max
    this.placeholder = config.placeholder
    this.hint = config.hint
    this.props = config.props
    this.filterable = config.filterable || false
    this.sortable = config.sortable || false
    this.custom = config.custom || false
  }

  buildFilterQuery(attribute, operator, value) {
    const isNullOperator = [FilterOperators.NULL, FilterOperators.NOT_NULL].includes(operator)
    return {
      [attribute]: {
        [operator]: value ?? isNullOperator
      }
    }
  }

  forPresenter(value) {
    return value
  }

  forFilter() {
    return {
      name: this.name,
      label: this.label,
      custom: this.custom,
      props: this.props,
      defaultValue: null,
      value: null,
      defaultOperator: FilterOperators.EQUALS,
      operator: FilterOperators.EQUALS,
      type: 'number',
      availableOperators: [
        FilterOperators.EQUALS,
        FilterOperators.NOT_EQUALS,
        FilterOperators.NULL,
        FilterOperators.NOT_NULL,
        FilterOperators.LESS_THAN,
        FilterOperators.LESS_THAN_OR_EQUAL_TO,
        FilterOperators.GREATER_THAN,
        FilterOperators.GREATER_THAN_OR_EQUAL_TO,
        FilterOperators.BETWEEN
      ],
      buildFilterQuery: this.buildFilterQuery
    }
  }

  forSort() {
    return {
      name: this.name,
      label: this.label
    }
  }

  forFormInitialValue(value) {
    return value
  }

  forFilterInitialValue(value) {
    return value
  }

  forFilterCast() {
    return yup.number().nullable(true).label(this.label)
  }

  forFormCast() {
    return yup.number().nullable(true).label(this.label)
  }

  forFormRules() {
    const output = []

    const integerFn = (rule, value, callback) => {
      if (!value) {
        callback()
        return
      }

      if (isInteger(value)) {
        callback()
        return
      }

      callback(new Error(i18nTranslate('validation.number.integer').replace('{path}', this.label)))
    }

    output.push({
      validator: integerFn
    })

    if (this.required) {
      output.push({
        type: 'number',
        required: Boolean(this.required),
        message: i18nTranslate('validation.mixed.required').replace('{path}', this.label)
      })
    }

    if (this.min || this.min === 0) {
      output.push({
        type: 'number',
        min: this.min,
        message: i18nTranslate('validation.number.min')
          .replace('{path}', this.label)
          .replace('{min}', this.min)
      })
    }

    if (this.max || this.max === 0) {
      output.push({
        type: 'number',
        max: this.max,
        message: i18nTranslate('validation.number.max')
          .replace('{path}', this.label)
          .replace('{max}', this.max)
      })
    }

    return output
  }

  forExport() {
    return yup.mixed().label(this.label)
  }

  forImport() {
    let yupChain = yup.number().nullable(true).label(this.label)

    if (this.required) {
      yupChain = yupChain.required()
    }

    if (this.min || this.min === 0) {
      yupChain = yupChain.min(this.min)
    }

    if (this.max) {
      yupChain = yupChain.max(this.max)
    }

    return yupChain
  }
}
