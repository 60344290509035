import useAuthStore from '@/modules/auth/store'

export default async function ({ to }) {
  if (!to.meta || !to.meta.emailVerified) {
    return
  }

  const authStore = useAuthStore()

  // Ensure user has email verified, if not redirect to /email-unverified
  if (!authStore.currentUser.email_verified_at && to.name !== 'email-unverified') {
    throw { name: 'email-unverified', params: { email: authStore.currentUser.email } }
  }
}
