import useAuthStore from '@/modules/auth/store'

export default async function ({ to }) {
  const authStore = useAuthStore()

  if (!to.meta || !to.meta.auth) {
    return
  }

  // Ensure user is logged in, if not redirect to /login
  if (!authStore.currentUser && to.name !== 'login') {
    try {
      await authStore.doFetchCurrentUser()
    } catch (err) {
      throw { name: 'login' }
    }
  }
}
