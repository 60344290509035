<template>
  <form class="relative" @submit.prevent="$emit('submit')">
    <div v-for="(field, index) of fields" :key="field.id">
      <hr v-if="field.type === 'separator'" class="my-4 border-gray-15" />
      <div v-else class="mb-2 flex items-start">
        <label
          v-if="field.label"
          :for="field.id"
          class="w-32 block font-normal text-sm text-gray-60 shrink-0 pt-1.5"
          >{{ field.label }}</label
        >
        <component
          :is="field.component"
          :id="field.id"
          :autofocus="index === 0"
          :class="field.class ?? undefined"
          :data-test="field.id"
          :inline="true"
          :mode="field.mode ?? undefined"
          :multiple="field.multiple ?? undefined"
          :placeholder="field.placeholder"
          :tabindex="index"
          :type="field.type ?? undefined"
          v-bind="modelPropsToBind(field)"
          v-on="modelEventsToBind(field)"
        />
      </div>
    </div>
  </form>
</template>

<script setup>
const props = defineProps({
  fields: {
    type: Array,
    required: true
  }
})

const model = defineModel({ type: Object, required: true })

defineEmits(['submit'])

const modelPropsToBind = (field) => {
  return field.attributes && Array.isArray(field.attributes) && !field.multiple
    ? field.attributes.reduce(
        (acc, attribute) => {
          acc[attribute] = model.value[attribute]
          return acc
        },
        { ...field.props }
      )
    : { ...field.props, modelValue: model.value[field.id] }
}

const modelEventsToBind = (field) => {
  return field.attributes && Array.isArray(field.attributes) && !field.multiple
    ? field.attributes.reduce((acc, attribute) => {
        acc[`update:${attribute}`] = (v) => (model.value[attribute] = v)
        return acc
      }, {})
    : {
        'update:modelValue': (v) => {
          model.value[field.id] = v
        }
      }
}
</script>
