import Icon from '@/shared/components/Icon.vue'
import PageHeader from '@/shared/components/Page/PageHeader.vue'
import PageContent from '@/shared/components/Page/PageContent.vue'
import PageLoader from '@/shared/components/Page/PageLoader.vue'
import InlineForm from '@/shared/components/Form/InlineForm/InlineForm.vue'
import FormErrors from '@/shared/components/Form/FormErrors.vue'
import PasswordCreator from '@/shared/components/Form/PasswordCreator.vue'
import Spinner from '@/shared/components/Spinner.vue'
import OptionsMenu from '@/shared/components/OptionsMenu.vue'
import FilterText from '@/shared/components/Filter/FilterTypeText.vue'
import EmptyState from '@/shared/components/EmptyState.vue'
import Tag from '@/shared/components/Tag.vue'
import Avatar from '@/shared/components/Avatar.vue'
import Collapse from '@/shared/components/Collapse.vue'

/**
 * All shared components are globally registered, so there's no need to import them from other components
 */
export default {
  components: {
    'app-icon': Icon,
    'app-avatar': Avatar,
    'app-tag': Tag,
    'app-page-header': PageHeader,
    'app-page-content': PageContent,
    'app-page-loader': PageLoader,
    'app-empty-state': EmptyState,
    'app-options-menu': OptionsMenu,
    'app-collapse': Collapse,
    'app-spinner': Spinner,
    'app-inline-form': InlineForm,
    'app-form-errors': FormErrors,
    'app-filter-text': FilterText,
    'app-password-creator': PasswordCreator
  }
}
