<template>
  <transition mode="out-in" name="fade">
    <component
      :is="component"
      :active-view="activeView"
      :class="{ 'px-6 py-4': activeView.type !== 'calendar' }"
      v-bind="computedProps"
      @view-record="$emit('view-record', $event)"
      @new-record="$emit('new-record', $event)"
      @delete-record="$emit('delete-record', $event)"
      @edit-record="$emit('edit-record', $event)"
    >
      <template v-for="(_, name) in $slots" #[name]="{ columnData, rowData }">
        <slot :column-data="columnData" :name="name" :row-data="rowData">
          {{ columnData.name }}
        </slot>
      </template>
    </component>
  </transition>
</template>
<script setup>
import { computed } from 'vue'
import useViewStore from '@/modules/view/store'
import ViewTypeTable from '@/modules/view/components/type/ViewTypeTable.vue'
import ViewTypeBoard from '@/modules/view/components/type/ViewTypeBoard.vue'
import ViewTypeCalendar from '@/modules/view/components/type/ViewTypeCalendar.vue'

const props = defineProps({
  module: {
    type: String,
    required: true
  },
  attributes: {
    type: Array,
    default: () => []
  },
  boardColumns: {
    type: Array,
    default: () => []
  },
  rows: {
    type: Array,
    default: () => []
  },
  rowHeight: {
    type: Number,
    default: 57
  },
  records: {
    type: Object,
    default: () => {}
  },
  count: {
    type: Number,
    default: 0
  },
  loading: {
    type: Boolean,
    default: false
  },
  optionsMenuItems: {
    type: Array,
    default: () => []
  },
  fetchFn: {
    type: Function,
    default: () => {}
  },
  updateFn: {
    type: Function,
    default: () => {}
  },
  deleteFn: {
    type: Function,
    default: () => {}
  },
  showSum: {
    type: Boolean,
    default: false
  },
  withActions: {
    type: Boolean,
    default: true
  }
})

defineEmits(['new-record', 'edit-record', 'delete-record', 'view-record'])
const viewStore = useViewStore()
const activeView = computed(() => viewStore.moduleActiveView(props.module))

const computedProps = computed(() => {
  switch (activeView.value.type) {
    case 'table':
      return {
        module: props.module,
        columns: props.attributes,
        activeView: activeView.value,
        rows: props.rows,
        rowHeight: props.rowHeight,
        count: props.count,
        optionsMenuItems: props.optionsMenuItems,
        fetchFn: props.fetchFn,
        deleteFn: props.deleteFn,
        withActions: props.withActions
      }
    case 'board':
      return {
        module: props.module,
        boardColumns: props.boardColumns,
        activeView: activeView.value,
        records: props.records,
        optionsMenuItems: props.optionsMenuItems,
        fetchFn: props.fetchFn,
        updateFn: props.updateFn,
        showSum: props.showSum
      }
    case 'calendar':
      return {
        module: props.module,
        activeView: activeView.value,
        records: props.records,
        fetchFn: props.fetchFn,
        updateFn: props.updateFn
      }
    default:
      return {}
  }
})

const component = computed(() => {
  switch (activeView.value.type) {
    case 'table':
      return ViewTypeTable
    case 'board':
      return ViewTypeBoard
    case 'calendar':
      return ViewTypeCalendar
    default:
      return null
  }
})
</script>
