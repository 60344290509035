import authAxios from '@/shared/axios/auth-axios'

export default class AuthService {
  static async csrfCookie() {
    const { data } = await authAxios.get('/sanctum/csrf-cookie')
    return data
  }

  static async me() {
    const { data } = await authAxios.get('/auth/me')
    return data
  }

  static async login(email, password, remember = true) {
    const { data } = await authAxios.post('/auth/login', { email, password, remember })
    return data
  }

  static async logout() {
    const { data } = await authAxios.post('/auth/logout')
    return data
  }

  static async signup(email, password, password_confirmation) {
    const { data } = await authAxios.post('/auth/register', {
      email,
      password,
      password_confirmation
    })
    return data
  }

  static async forgotPassword(email) {
    const { data } = await authAxios.post('/auth/forgot-password', { email })
    return data
  }

  static async resetPassword(password, password_confirmation, token) {
    const { data } = await authAxios.post('/auth/reset-password', {
      password,
      password_confirmation,
      token
    })
    return data
  }

  static async resendEmailVerificationLink() {
    const { data } = await authAxios.post('/auth/email/verification-notification')
    return data
  }
}
