<template>
  <div class="flex basis-full">
    <div class="max-w-2xs w-full px-6 py-4 overflow-auto">
      <div
        class="bg-gray-5 text-gray-80 font-medium text-sm px-3 h-8 shrink-0 flex items-center justify-between rounded-lg mb-2"
      >
        <span class="block">No due date</span>
        <span v-if="model[null]" class="text-xs px-2 py-1 bg-gray-15 rounded-md ml-1">{{
          model[null].length
        }}</span>
      </div>
      <ViewTypeCalendarDraggable
        v-model="model"
        :activeView="activeView"
        :date="null"
        :module="module"
        @item-dragged="handleItemDragged"
        @item-clicked="emit('view-record', $event)"
      />
    </div>
    <ViewTypeCalendarGrid
      v-model="model"
      :active-date="activeDate"
      :active-view="activeView"
      :module="module"
      @item-dragged="handleItemDragged"
      @item-clicked="emit('view-record', $event)"
      @active-date-next-month="handleNextMonth"
      @active-date-prev-month="handlePrevMonth"
      @active-date-set-today="activeDate = new Date()"
    />
  </div>
</template>

<script setup>
import { computed, onBeforeMount, reactive, ref, watch } from 'vue'
import { cloneDeep } from 'lodash'
import { filtersAreDifferent } from '@/shared/filter/different-util'
import { addDays, addMonths, format, isSameMonth, startOfMonth } from 'date-fns'
import ViewTypeCalendarGrid from '@/modules/view/components/type/calendar/ViewTypeCalendarGrid.vue'
import ViewTypeCalendarDraggable from '@/modules/view/components/type/calendar/ViewTypeCalendarDraggable.vue'

const props = defineProps({
  module: {
    type: String,
    required: true
  },
  activeView: {
    type: Object,
    default: () => {}
  },
  records: {
    type: Object,
    default: () => {}
  },
  fetchFn: {
    type: Function,
    default: () => {}
  },
  updateFn: {
    type: Function,
    default: () => {}
  }
})

const emit = defineEmits(['view-record'])

const getDaysOfMonth = (date) => {
  let day = startOfMonth(date)
  const days = [day]
  while (isSameMonth(new Date(day), new Date(addDays(day, 1)))) {
    day = addDays(day, 1)
    days.push(day)
  }
  return days
}

const loading = ref(false)

const activeDate = ref(new Date())

const model = ref({})

const fetchRecords = async () => {
  loading.value = true
  await props.fetchFn(props.activeView)
  loading.value = false
}

const generateModelKeysForDate = (date) => {
  if (model.value[format(date, 'y-MM-dd')] === undefined) {
    getDaysOfMonth(date).map((d) => {
      const formattedDate = format(d, 'y-MM-dd')
      if (model.value[formattedDate] === undefined) {
        model.value[formattedDate] = reactive([])
      }
    })
  }
}

const generateModel = (calendableDate, clearModel = false) => {
  if (clearModel) {
    model.value = {
      null: reactive([])
    }
  }

  Object.values(props.records).forEach((record) => {
    if (record[calendableDate] === null) {
      model.value[null].push(record)
    } else {
      const formattedDate = format(record[calendableDate], 'y-MM-dd')

      if (model.value[formattedDate] !== undefined) {
        model.value[formattedDate].push(record)
      } else {
        model.value[formattedDate] = reactive([record])
      }
    }
  })
  generateModelKeysForDate(activeDate.value)
}

const handlePrevMonth = () => {
  const newDate = addMonths(activeDate.value, -1)
  generateModelKeysForDate(newDate)
  activeDate.value = newDate
}

const handleNextMonth = () => {
  const newDate = (activeDate.value = addMonths(activeDate.value, 1))
  generateModelKeysForDate(newDate)
  activeDate.value = newDate
}

const handleItemDragged = async ({ id, to }) => {
  await props.updateFn(id, { ...props.records[id], [props.activeView.date.name]: to ?? null })
}

onBeforeMount(async () => {
  await fetchRecords()
  generateModel(props.activeView.date.name, true)
})

watch(
  () => props.records,
  () => {
    generateModel(props.activeView.date.name, true)
  },
  { deep: true }
)

watch(
  () => props.activeView.date,
  (newValue, oldValue) => {
    generateModel(newValue.name, newValue.name !== oldValue.name)
  },
  { deep: true }
)

const watchableView = computed(() => {
  const { filter, query } = cloneDeep(props.activeView)

  return {
    filter,
    query
  }
})

watch(
  watchableView,
  async (newValue, oldValue) => {
    if (
      filtersAreDifferent(newValue.filter, oldValue.filter) ||
      newValue.query !== oldValue.query
    ) {
      await fetchRecords()
    }
  },
  { deep: true }
)
</script>
