<template>
  <div class="flex items-center">
    <FilterDropdown
      v-if="showFilter"
      v-model="computedFilter"
      :items="availableFilterableAttributes"
    />
    <SortDropdown
      v-if="showSort"
      v-model="computedSort"
      :active-view="activeView"
      :items="availableSortableAttributes"
    />
    <FilterSearchBar v-if="showSearch" v-model="computedQuery" />
    <Teleport to="#teleport-page-filters">
      <div v-if="showViewConfig" :class="$style.pageFilters">
        <div class="flex items-center justify-between w-full">
          <div class="flex items-center">
            <ViewActiveSort
              v-if="hasSort"
              v-model="computedSort"
              :available-options="availableSortableAttributes"
              :options="sortableAttributes"
            />
            <ViewActiveDate v-if="hasDate" v-model="computedDate" :options="calendableAttributes" />
            <span v-if="hasSort || hasDate" class="mx-2 text-gray-10">|</span>
            <FilterList
              v-if="hasFilter"
              v-model="computedFilter"
              :fields="modelFields"
              class="mr-2"
            />
            <FilterDropdown
              v-if="showFilter"
              v-model="computedFilter"
              :items="availableFilterableAttributes"
            >
              <template #trigger="{ onClick }">
                <button class="btn btn--shadow btn--sm" @click="onClick">
                  <app-icon name="plus"></app-icon>
                  Add filter
                </button>
              </template>
            </FilterDropdown>
          </div>
          <div v-if="hasChanges" class="flex items-center">
            <button class="btn btn--shadow btn--sm" @click="handleResetView">Reset</button>
            <button class="btn btn--shadow btn--primary btn--sm ml-2" @click="handleSaveView">
              <app-icon name="save"></app-icon>
              Save
            </button>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { filtersAreDifferent } from '@/shared/filter/different-util'
import useViewStore from '@/modules/view/store'
import FilterDropdown from '@/shared/components/Filter/FilterDropdown.vue'
import SortDropdown from '@/shared/components/Sort/SortDropdown.vue'
import FilterSearchBar from '@/shared/components/Filter/FilterSearchBar.vue'
import FilterList from '@/shared/components/Filter/FilterList.vue'
import modules from '@/modules'
import { cloneDeep } from 'lodash'
import ViewActiveSort from '@/modules/view/components/active/ViewActiveSort.vue'
import ViewActiveDate from '@/modules/view/components/active/ViewActiveDate.vue'

const viewStore = useViewStore()

const props = defineProps({
  module: {
    type: String,
    required: true
  },
  showSort: {
    type: Boolean,
    default: true
  },
  showFilter: {
    type: Boolean,
    default: true
  },
  showSearch: {
    type: Boolean,
    default: true
  }
})

const activeView = computed(() => viewStore.moduleActiveView(props.module))
const computedFilter = computed({
  get: () => activeView.value?.filter ?? {},
  set: (value) => {
    activeView.value.filter = value
  }
})
const computedQuery = computed({
  get: () => activeView.value?.query ?? null,
  set: (value) => {
    activeView.value.query = value
  }
})
const computedSort = computed({
  get: () => activeView.value?.sort ?? [],
  set: (value) => {
    activeView.value.sort = value
  }
})
const computedDate = computed({
  get: () => activeView.value?.date ?? {},
  set: (value) => {
    activeView.value.date = value
  }
})

const modelFields = computed(() => modules[props.module].model.fields)

const calendableAttributes = computed(() =>
  Object.values(modelFields.value)
    .filter((i) => i.calendable)
    .map((i) => i.forCalendar())
)

const availableFilterableAttributes = computed(() =>
  Object.values(modelFields.value).filter((i) => {
    return i.filterable && !Object.keys(computedFilter.value.attributes).includes(i.name)
  })
)

const sortableAttributes = computed(() => {
  return Object.values(modelFields.value)
    .filter((i) => i.sortable)
    .map((i) => i.forSort())
})

const availableSortableAttributes = computed(() =>
  sortableAttributes.value.filter((i) => {
    return !computedSort.value.some((s) => s.id === i.name)
  })
)

const hasFilter = computed(() => Object.keys(computedFilter.value.attributes).length > 0)
const hasSort = computed(() => computedSort.value.length > 0)
const hasDate = computed(() => activeView.value.type === 'calendar' && computedDate.value)

const showViewConfig = computed(
  () => hasSort.value || hasFilter.value || hasDate.value || hasChanges.value
)

const hasChanges = computed(() => {
  return (
    filtersAreDifferent(computedFilter.value, activeView.value.settings.filter) ||
    JSON.stringify(computedSort.value) !== JSON.stringify(activeView.value.settings.sort) ||
    (activeView.value.settings.date &&
      JSON.stringify(computedDate.value) !== JSON.stringify(activeView.value.settings.date))
  )
})

const handleResetView = async () => {
  computedFilter.value = cloneDeep(activeView.value.settings.filter)
  computedSort.value = cloneDeep(activeView.value.settings.sort)
}

const handleSaveView = async () => {
  const payload = {
    date: computedDate.value,
    sort: computedSort.value,
    filter: {
      attributes: Object.keys(computedFilter.value.attributes).reduce((acc, item) => {
        const attribute = computedFilter.value.attributes[item]
        acc[item] = {
          ...attribute,
          id: item,
          defaultOperator: attribute.operator,
          defaultValue: attribute.value
        }
        return acc
      }, {}),
      conjunction: '$and'
    }
  }

  await viewStore.doUpdate(activeView.value.id, {
    settings: JSON.stringify(payload)
  })
}
</script>

<style lang="scss" module>
.pageFilters {
  min-height: 65px;
  @apply flex items-center w-full border-b border-gray-15 px-6;
}
</style>
