<template>
  <div :class="$style.pageLoaderOverlay" />
  <div :class="$style.pageLoaderSpinner">
    <app-spinner size="8"></app-spinner>
    <span class="text-sm text-gray-60 block mt-4">Loading, please wait...</span>
  </div>
</template>

<script lang="ts" setup></script>
<style lang="scss" module>
.pageLoaderOverlay {
  background-color: rgba(255, 255, 255, 0.95);
  @apply absolute inset-0 z-30 blur;
}

.pageLoaderSpinner {
  @apply absolute inset-x-0 z-30 flex flex-col items-center justify-center h-5/6;
}
</style>
