<template>
  <div>
    <slot :on-click="handleToggle" name="trigger">
      <button
        v-tooltip.bottom="isDisabledTooltip"
        :class="{ btn__active: model.length > 0 }"
        :disabled="isDisabled"
        class="btn btn--secondary ml-2"
        @click="handleToggle"
      >
        <app-icon class="stroke-gray-100" name="sort" />
        Sort
      </button>
    </slot>
    <TieredMenu ref="menu" :model="computedItems" popup @hide="$emit('hide')" @show="$emit('show')">
      <template #item="{ item }">
        <div class="cursor-pointer p-2">{{ item.label }}</div>
      </template>
    </TieredMenu>
  </div>
</template>
<script setup>
import TieredMenu from 'primevue/tieredmenu'
import { computed, ref } from 'vue'

const props = defineProps({
  items: {
    type: Array,
    required: true
  },
  activeView: {
    type: Object,
    default: () => {}
  }
})

defineEmits(['show', 'hide'])

const model = defineModel({
  type: Object,
  required: true
})

const menu = ref()

const isDisabled = computed(() => {
  return model.value.length > 0 || props.activeView.type === 'board'
})

const isDisabledTooltip = computed(() => {
  if (props.activeView.type === 'board') return 'Sorting is not available on boards'
  else
    return model.value.length > 0
      ? `Already sorted by ${model.value.map((i) => i.label).join(', ')}`
      : ''
})

const computedItems = computed(() => {
  return props.items.map((i) => {
    return {
      ...i,
      command: () => handleAddSortAttribute(i)
    }
  })
})

const handleAddSortAttribute = (attr) => {
  model.value.push({
    id: attr.name,
    label: attr.label,
    value: 'asc'
  })
}

const handleToggle = (event) => {
  menu.value.toggle(event)
}
</script>
