export default [
  {
    name: 'post-list',
    path: 'posts',
    component: () => import('@/modules/post/pages/PostListPage.vue'),
    meta: { auth: true, emailVerified: true, tenant: true, init: true }
  },
  {
    name: 'post-view',
    path: 'posts/:id',
    component: () => import('@/modules/post/pages/PostViewPage.vue'),
    meta: { auth: true, emailVerified: true, tenant: true, init: true },
    props: true
  }
]
