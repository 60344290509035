export default [
  {
    name: 'settings',
    path: 'settings',
    redirect: '/settings/account',
    component: () => import('@/modules/settings/layout/SettingsLayout.vue'),
    meta: { auth: true, emailVerified: true, tenant: true, init: true },
    children: [
      {
        name: 'settings-account',
        path: 'account',
        component: () => import('@/modules/settings/pages/SettingsAccountPage.vue')
      },
      {
        name: 'settings-workspace',
        path: 'workspace',
        component: () => import('@/modules/settings/pages/SettingsWorkspacePage.vue')
      },
      {
        name: 'settings-users',
        path: 'users',
        component: () => import('@/modules/settings/pages/SettingsUsersPage.vue')
      }
    ]
  }
]
