import sharedActions from '@/shared/store/actions'
import TenantService from '@/modules/tenant/tenant-service'
import authAxios from '@/shared/axios/auth-axios'

export default {
  ...sharedActions('tenant', TenantService),
  async doDestroyDemoData(id) {
    await authAxios.delete(`/tenant/${id}/demo`)
    return await this.doFind(id)
  }
}
