import authAxios from '@/shared/axios/auth-axios'

export default class BrandService {
  static async update(id, data, isFormData = false) {
    // https://stackoverflow.com/questions/74471540/multipart-form-data-not-working-on-axios-put-request

    const response = isFormData
      ? await authAxios.post(`/brand/${id}`, data)
      : await authAxios.put(`/brand/${id}`, data)

    return response.data
  }

  static async destroy(id) {
    await authAxios.delete(`/brand/${id}`)

    return true
  }

  static async create(data) {
    const response = await authAxios.post(`/brand`, data)

    return response.data
  }

  static async find(id) {
    const response = await authAxios.get(`/brand/${id}`)
    return response.data
  }

  static async list(
    query = undefined,
    filter = undefined,
    sort = undefined,
    perPage = undefined,
    page = undefined
  ) {
    const params = {
      query,
      filter,
      sort,
      perPage,
      page
    }

    const response = await authAxios.get(`/brand`, { params })

    return response.data
  }
}
