import authAxios from '@/shared/axios/auth-axios'

export default class NotificatioService {
  static async markAsRead(ids) {
    const data = {
      notifications: ids ?? []
    }

    const response = await authAxios.post(`/notification/read`, data)

    return response.data
  }

  static async archive(ids) {
    const data = {
      notifications: ids ?? []
    }

    const response = await authAxios.post(`/notification/archive`, data)

    return response.data
  }

  static async list(perPage = 20, page = 1) {
    const params = {
      perPage,
      page
    }

    const response = await authAxios.get(`/notification`, {
      params
    })

    return response.data
  }

  static async find(id) {
    const response = await authAxios.get(`/notification/${id}`)
    return response.data
  }
}
