import Axios from 'axios'
import AuthCurrentTenant from '@/modules/auth/auth-current-tenant'

let authAxios

if (!authAxios) {
  authAxios = Axios.create({
    baseURL: import.meta.env.VITE_BACKEND_URL,
    withCredentials: true,
    withXSRFToken: true
  })

  authAxios.defaults.headers.common['x-tenant-id'] = AuthCurrentTenant.get() ?? undefined
}

export default authAxios
