import Errors from '@/shared/error/errors'
import Toast from '@/shared/components/Toast'

const buildQuery = (view) => {
  if (!view) return undefined

  const { query } = view

  return query
}

const buildFilter = (view) => {
  if (!view) return undefined

  const { filter } = view
  if (!filter) return undefined

  const { attributes, conjunction } = filter

  if (!attributes || Object.keys(attributes).length === 0) {
    return undefined
  }

  return {
    [conjunction]: Object.keys(attributes)
      .map((attr) => attributes[attr].query)
      .filter((query) => ![undefined, null].includes(query))
  }
}

const buildSort = (view) => {
  if (!view) return undefined

  const { sort } = view
  if (!sort) return undefined

  return sort.map((i) => `${i.id}:${i.value}`)
}

const buildPerPage = (view) => {
  if (!view) return undefined

  const { paginator } = view
  if (!paginator) return undefined

  const { perPage } = paginator
  if (!perPage) return undefined

  return paginator.perPage
}

const buildPage = (view, keepPagination) => {
  if (!view) return undefined

  const { paginator } = view
  if (!paginator) return undefined

  const { perPage, first } = paginator
  if (!perPage || !first) return undefined

  if (!keepPagination) paginator.first = 0

  return Math.round(paginator.first / paginator.perPage + 1)
}

export default (moduleName, moduleService = null) => {
  const entityName = moduleName.charAt(0).toUpperCase() + moduleName.slice(1)
  return {
    async doFetch(view = null, keepPagination = false) {
      try {
        this.list.loading = true

        const { data, meta } = await moduleService.list(
          buildQuery(view),
          buildFilter(view),
          buildSort(view),
          buildPerPage(view),
          buildPage(view, keepPagination)
        )

        if (keepPagination) {
          data.map((record) => {
            this.records[record.id] = record
            if (this.list.ids.indexOf(record.id) === -1) {
              this.list.ids.push(record.id)
            }
          })
        } else {
          this.records = Object.values(data).reduce((acc, record) => {
            acc[record.id] = record
            return acc
          }, {})
          this.list.ids = data.map((record) => record.id)
        }

        this.count = meta?.total ?? data.length
        this.list.loading = false

        return data
      } catch (error) {
        Errors.handle(error)
      }
    },

    async doFind(id) {
      try {
        const { data: record } = await moduleService.find(id)
        this.records[id] = record
        return record
      } catch (error) {
        Errors.handle(error)
      }
    },

    async doCreate(values) {
      try {
        const { data: record } = await moduleService.create(values)
        this.records[record.id] = record
        this.count++
        Toast.info(`${entityName} was successfully created`)
        return record
      } catch (error) {
        Errors.handle(error)
      }
    },

    async doUpdate(id, values, isFormData = false) {
      try {
        const { data: record } = await moduleService.update(id, values, isFormData)
        this.records[record.id] = record
        Toast.info(`${entityName} was successfully updated`)
        return record
      } catch (error) {
        Errors.handle(error)
      }
    },

    async doDestroy(id) {
      try {
        await moduleService.destroy(id)
        delete this.records[id]
        this.count--
        this.list.ids = this.list.ids.filter((rId) => rId !== id)
        Toast.info(`${entityName} was successfully deleted`)
      } catch (error) {
        Errors.handle(error)
      }
    }
  }
}
