<template>
  <div :class="[$style.pageContent, { [$style.pageContentList]: type === 'list' }]">
    <PageContentList v-if="type === 'list'" v-model:form="computedForm" v-bind="computedProps">
      <template v-for="(_, name) in $slots" #[name]="{ columnData, rowData }">
        <slot :column-data="columnData" :name="name" :row-data="rowData">
          {{ columnData?.name }}
        </slot>
      </template>
    </PageContentList>
    <PageContentView v-else-if="type === 'view'" v-bind="computedProps">
      <template v-for="(_, name) in $slots" #[name]>
        <slot :name="name"></slot>
      </template>
    </PageContentView>
    <slot v-else-if="type === 'custom'"></slot>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import PageContentList from '@/shared/components/Page/PageContentList.vue'
import PageContentView from '@/shared/components/Page/PageContentView.vue'

const props = defineProps({
  module: {
    type: String,
    required: true
  },
  type: {
    type: String,
    default: 'list'
  },
  attributes: {
    type: Array,
    default: () => []
  },
  boardColumns: {
    type: Array,
    default: () => []
  },
  records: {
    type: Object,
    default: () => {}
  },
  store: {
    type: Object,
    default: () => {}
  },
  rowHeight: {
    type: Number,
    default: 57
  },
  showSum: {
    type: Boolean,
    default: false
  },
  optionsMenuItems: {
    type: Array,
    default: () => []
  },
  withActions: {
    type: Boolean,
    default: true
  }
})

const form = defineModel('form', {
  type: Object,
  default: () => {}
})

const computedForm = computed(() => {
  return props.type === 'list' ? form : undefined
})

const computedProps = computed(() => {
  return props.type === 'view'
    ? {}
    : {
        ...props,
        type: undefined
      }
})
</script>

<style lang="scss" module>
.pageContent {
  @apply flex basis-full overflow-auto relative;
}

.pageContentList {
  @apply h-fit;
}
</style>
