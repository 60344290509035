import authAxios from '@/shared/axios/auth-axios'

/**
 * Auth Current Tenant
 *
 * This class helps us manage the currentTenant that is stored within browser's localStorage
 */
export default class AuthCurrentTenant {
  static get() {
    return localStorage.getItem('tenantId') || null
  }

  static set(tenantId) {
    if (!tenantId) {
      return this.clear()
    }

    localStorage.setItem('tenantId', tenantId)
    authAxios.defaults.headers['x-tenant-id'] = tenantId
  }

  static clear() {
    localStorage.removeItem('tenantId')
  }
}
