import * as yup from 'yup'
import { i18nTranslate } from '@/i18n'
import GenericField from '@/shared/fields/generic-field'
import FilterOperators from '@/enums/FilterOperators'

export default class BooleanField extends GenericField {
  constructor(name, label, config = {}) {
    super(name, label, config)

    this.hint = config.hint
    this.yesLabel = config.yesLabel || i18nTranslate('common.yes')
    this.noLabel = config.noLabel || i18nTranslate('common.no')
    this.filterable = config.filterable || false
    this.custom = config.custom || false
  }

  buildFilterQuery(attribute, operator) {
    return {
      [attribute]: {
        [operator]: true
      }
    }
  }

  forPresenter(value) {
    return value ? this.yesLabel : this.noLabel
  }

  forFilter() {
    return {
      name: this.name,
      label: this.label,
      props: {},
      defaultValue: null,
      value: null,
      defaultOperator: FilterOperators.NULL,
      operator: FilterOperators.NULL,
      type: 'boolean',
      availableOperators: [FilterOperators.NULL, FilterOperators.NOT_NULL],
      buildFilterQuery: this.buildFilterQuery
    }
  }

  forFilterPreview(value) {
    if (value == null) {
      return null
    }
    return value ? this.yesLabel : this.noLabel
  }

  forFilterInitialValue(value) {
    return value
  }

  forFormInitialValue(value) {
    return value
  }

  forFormCast() {
    return yup.bool().default(false).label(this.label)
  }

  forFilterCast() {
    return yup.bool().nullable(true).label(this.label)
  }

  forExport() {
    return yup.bool().nullable(true).default(false).label(this.label)
  }

  forImport() {
    return yup.bool().default(false).label(this.label)
  }
}
