import { onMounted, ref, watch } from 'vue'
import debounce from 'lodash/debounce'

export function useDebouncedSearch(
  searchFn,
  reactiveFilter = ref({}),
  searchOnMounted = true,
  timer = 500
) {
  const items = ref([])
  const loading = ref(items.value.length === 0)

  const search = debounce(async (query) => {
    loading.value = true
    const { data } = await searchFn(query, reactiveFilter.value, undefined, 20)
    items.value = data
    loading.value = false
  }, timer)

  onMounted(() => {
    if (searchOnMounted) {
      search()
    }
  })

  watch(
    reactiveFilter,
    () => {
      search()
    },
    { deep: true }
  )

  return {
    items,
    loading,
    search
  }
}
