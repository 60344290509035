import { createPinia } from 'pinia'

let store

export const createStore = () => {
  if (!store) {
    store = createPinia()
  }

  return store
}

export { store }
