<template>
  <Avatar
    :class="computedClass"
    :image="entity.image_url"
    :label="computedLabel"
    :shape="shape"
    :size="size"
  />
</template>
<script setup>
import Avatar from 'primevue/avatar'
import { computed } from 'vue'

const props = defineProps({
  entity: {
    type: Object,
    default: () => {}
  },
  shape: {
    type: String,
    default: 'circle'
  },
  size: {
    type: String,
    default: 'normal'
  }
})

const randomColor = computed(() => {
  const colors = ['gray', 'purple', 'red', 'green', 'orange', 'pink', 'blue', 'lightBlue']
  return colors[props.entity.name?.length % colors.length]
})

const computedLabel = computed(() => {
  if (props.entity.image_url) return null
  else return props.entity.name[0] ?? ''
})

const computedClass = computed(() => {
  if (props.entity.image_url) return null
  else return `bg-${randomColor.value}-10 text-${randomColor.value}-100`
})
</script>
