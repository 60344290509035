import { i18nTranslate } from '@/i18n'

export default () => {
  const facebook = [
    'mobile_status_update',
    'created_note',
    'added_photos',
    'added_video',
    'shared_story',
    'created_group',
    'created_event',
    'wall_post',
    'app_created_story',
    'published_story',
    'tagged_in_photo'
  ]

  const instagram = ['image', 'carousel', 'reels', 'story']

  const all = { facebook, instagram }

  const allAsOptions = Object.keys(all).reduce((acc, platform) => {
    acc.push({
      id: platform,
      name: i18nTranslate(`platform.${platform}.label`),
      options: all[platform].map((o) => {
        return {
          id: o,
          name: i18nTranslate(`platform.${platform}.post_type.${o}`)
        }
      })
    })
    return acc
  }, [])

  return {
    facebook,
    instagram,
    all,
    allAsOptions
  }
}
