<template>
  <div>
    <button
      class="btn btn--secondary btn--pill btn--sm btn--filter btn__active"
      @click="handlePanelShow"
    >
      <app-icon v-if="model.name" class="!ml-0 !mr-1 h-4" name="calendar" />
      {{ computedLabel }}
      <app-icon name="chevron-down"></app-icon>
    </button>
    <Popover
      ref="panelRef"
      class="popover !max-w-3xs"
      @hide="expanded = false"
      @show="expanded = true"
    >
      <div class="p-2">
        <div class="text-xs text-gray-40 px-2 py-1">Due dates</div>
        <ul>
          <li
            v-for="option of options"
            :key="option.name"
            :class="{ 'text-purple-100': option.name === model.name }"
            class="p-2 text-sm hover:bg-purple-10 cursor-pointer block w-full rounded-md"
            @click="handleOptionClick(option)"
          >
            {{ option.label }}
          </li>
        </ul>
      </div>
    </Popover>
  </div>
</template>
<script setup>
import Popover from 'primevue/popover'
import { computed, ref } from 'vue'

const props = defineProps({
  options: {
    type: Array,
    required: true
  }
})

const model = defineModel({
  type: Object,
  required: true
})

const expanded = ref(false)

const panelRef = ref()

const computedLabel = computed(() => {
  return `Organized by ${model.value.label.toLowerCase()} date`
})

const handlePanelShow = async (event) => {
  await panelRef.value.show(event)
}

const handlePanelHide = async () => {
  await panelRef.value.hide()
}

const handleOptionClick = (option) => {
  model.value = option
  handlePanelHide()
}
</script>
