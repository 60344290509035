<template>
  <div>
    <InputText v-model="computedValue" class="mt-1" size="sm" />
  </div>
</template>
<script setup>
import InputText from 'primevue/inputtext'
import { computed } from 'vue'

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  operator: {
    type: String,
    required: true
  }
})

const model = defineModel({
  type: [String, null],
  required: true
})

const computedValue = computed({
  get() {
    return model.value
  },
  set(v) {
    model.value = v
  }
})
</script>
