<template>
  <div class="flex flex-wrap items-center gap-2">
    <div v-for="key of Object.keys(model.attributes)">
      <FilterListItem
        v-model="model.attributes[key]"
        :field="fields[key].forFilter()"
        @delete="handleDeleteAttribute(key)"
      />
    </div>
  </div>
</template>

<script setup>
import FilterListItem from '@/shared/components/Filter/FilterListItem.vue'

const props = defineProps({
  fields: {
    type: Object,
    required: true
  }
})

const model = defineModel({
  type: Object,
  default: () => {}
})

const handleDeleteAttribute = (attribute) => {
  delete model.value.attributes[attribute]
}
</script>
