<template>
  <div>
    <button
      class="btn btn--secondary btn--pill btn--sm btn--filter btn__active"
      @click="handlePanelShow"
    >
      <app-icon
        v-if="model.length === 1 && model[0].value === 'asc'"
        class="!ml-0 !mr-1 h-4"
        color="gray-60"
        name="arrow-up"
      />
      <app-icon
        v-else-if="model.length === 1 && model[0].value === 'desc'"
        class="!ml-0 !mr-1 h-4"
        color="gray-60"
        name="arrow-down"
      />
      <app-icon
        v-else-if="model.length !== 1"
        class="!ml-0 !mr-1 h-4"
        color="gray-60"
        name="arrow-switch-vertical"
      />
      {{ computedLabel }}
      <app-icon name="chevron-down"></app-icon>
    </button>
    <Popover
      ref="panelRef"
      class="popover !max-w-xs"
      @hide="expanded = false"
      @show="expanded = true"
    >
      <transition-group>
        <draggable key="sort" v-model="model" item-key="id" v-bind="dragOptions">
          <template #item="{ element }">
            <div class="flex items-center justify-between py-2 pl-4 pr-2">
              <div class="flex items-center gap-2">
                <app-icon class="h-4 w-4 cursor-move" color="gray-60" name="drag"></app-icon>
                <Select
                  v-model="element.id"
                  :options="options"
                  option-label="label"
                  option-value="name"
                ></Select>
                <Select
                  v-model="element.value"
                  :options="sortOptions"
                  option-label="label"
                  option-value="value"
                ></Select>
              </div>
              <button
                class="btn btn--shadow !h-6 !px-0 !w-6 !rounded"
                type="button"
                @click="handleRemoveSort(element)"
              >
                <app-icon class="h-4 w-4 !mr-0" color="gray-60" name="close" />
              </button>
            </div>
          </template>
        </draggable>
      </transition-group>
      <div class="p-2 pt-0 flex flex-col gap-y-1">
        <SortDropdown v-if="availableOptions.length" v-model="model" :items="availableOptions">
          <template #trigger="{ onClick }">
            <button class="btn btn--shadow btn--sm w-full !rounded !justify-start" @click="onClick">
              <app-icon color="gray-60" name="plus"></app-icon>
              Add sort
            </button>
          </template>
        </SortDropdown>
        <button class="btn btn--shadow btn--sm w-full !rounded !justify-start" @click="model = []">
          <app-icon color="gray-60" name="trash"></app-icon>
          Delete sort
        </button>
      </div>
    </Popover>
  </div>
</template>
<script setup>
import draggable from 'vuedraggable'
import Popover from 'primevue/popover'
import Select from 'primevue/select'
import { computed, ref } from 'vue'
import SortDropdown from '@/shared/components/Sort/SortDropdown.vue'

const props = defineProps({
  options: {
    type: Array,
    required: true
  },
  availableOptions: {
    type: Array,
    required: true
  }
})

const model = defineModel({
  type: Array,
  default: () => []
})

const dragOptions = ref({
  animation: 200,
  group: 'sort',
  disabled: false,
  ghostClass: 'ghost'
})

const expanded = ref(false)

const computedLabel = computed(() => {
  return model.value.length > 1 ? `${model.value.length} sorts` : `${model.value[0].label}`
})

const panelRef = ref()

const sortOptions = [
  {
    value: 'asc',
    label: 'Ascending'
  },
  {
    value: 'desc',
    label: 'Descending'
  }
]

const handlePanelShow = async (event) => {
  await panelRef.value.show(event)
}

const handlePanelHide = async () => {
  await panelRef.value.hide()
}

const handleRemoveSort = (attr) => {
  const index = model.value.findIndex((s) => s.id === attr.id)
  model.value.splice(index, 1)
}
</script>
