export default {
  EQUALS: '$eq',
  NOT_EQUALS: '$ne',
  CONTAINS: '$contains',
  NOT_CONTAINS: '$notContains',
  STARTS_WITH: '$startsWith',
  ENDS_WITH: '$endsWith',
  NULL: '$null',
  NOT_NULL: '$notNull',
  IN: '$in',
  NOT_IN: '$notIn',
  LESS_THAN: '$lt',
  LESS_THAN_OR_EQUAL_TO: '$lte',
  GREATER_THAN: '$gt',
  GREATER_THAN_OR_EQUAL_TO: '$gte',
  BETWEEN: '$between'
}
