import * as yup from 'yup'
import GenericField from '@/shared/fields/generic-field'
import { i18nTranslate } from '@/i18n'
import FilterOperators from '@/enums/FilterOperators'
import { isArray } from 'lodash'

export default class RelationToManyField extends GenericField {
  constructor(name, label, fetchFn, mapperFn, config = {}) {
    super(name, label, config)

    this.required = config.required
    this.placeholder = config.placeholder
    this.hint = config.hint
    this.fetchFn = fetchFn
    this.mapperFn = mapperFn
    this.filterable = config.filterable || false
    this.sortable = config.sortable || false
    this.custom = config.custom || false
  }

  buildFilterQuery(attribute, operator, value) {
    return {
      [attribute]: {
        id: {
          [operator]: isArray(value) ? value.map((i) => i.id) : value?.id
        }
      }
    }
  }

  forPresenter(value) {
    if (!value) {
      return []
    }

    return value.map((item) => this.mapperFn(item))
  }

  forFilter() {
    return {
      name: this.name,
      label: this.label,
      custom: this.custom,
      props: {
        fetchFn: this.fetchFn,
        async: true,
        multiple: true
      },
      defaultValue: [],
      value: [],
      defaultOperator: FilterOperators.IN,
      operator: FilterOperators.IN,
      type: 'entity',
      availableOperators: [
        FilterOperators.IN,
        FilterOperators.NOT_IN,
        FilterOperators.NULL,
        FilterOperators.NOT_NULL
      ],
      buildFilterQuery: this.buildFilterQuery
    }
  }

  forSort() {
    return {
      name: `${this.name}.name`,
      label: this.label
    }
  }

  forFilterPreview(value) {
    return value.map((item) => (item && item.label) || null).join(', ')
  }

  forFormInitialValue(value) {
    return this.forPresenter(value)
  }

  forFormRules() {
    const output = []

    if (this.required) {
      output.push({
        type: 'array',
        required: Boolean(this.required),
        message: i18nTranslate('validation.mixed.required').replace('{path}', this.label)
      })
    }

    if (this.min || this.min === 0) {
      output.push({
        type: 'array',
        min: this.min,
        message: i18nTranslate('validation.array.min')
          .replace('{path}', this.label)
          .replace('{min}', this.min)
      })
    }

    if (this.max || this.max === 0) {
      output.push({
        type: 'array',
        max: this.max,
        message: i18nTranslate('validation.array.max')
          .replace('{path}', this.label)
          .replace('{max}', this.max)
      })
    }

    return output
  }

  forFormCast() {
    return yup
      .array()
      .nullable(true)
      .label(this.label)
      .transform((value, originalValue) => {
        if (!originalValue || !originalValue.length) {
          return []
        }

        return originalValue.map((item) => item.id)
      })
  }

  forFilterCast() {
    return yup
      .mixed()
      .label(this.label)
      .transform((value, originalValue) => {
        if (!originalValue) {
          return []
        }

        return originalValue.map((item) => item.id)
      })
  }

  forExport() {
    return yup
      .mixed()
      .label(this.label)
      .transform((value, originalValue) => {
        if (!originalValue || !originalValue.length) {
          return null
        }

        return originalValue.map((v) => v.id).join(' ')
      })
  }

  forImport() {
    let yupChain = yup
      .array()
      .nullable(true)
      .label(this.label)
      .transform((value, originalValue) => {
        if (!originalValue) {
          return null
        }

        if (Array.isArray(originalValue)) {
          return originalValue
        }

        return originalValue
          .trim()
          .split(' ')
          .map((v) => v)
      })

    if (this.required || this.min) {
      yupChain = yupChain.required()
    }

    if (this.min || this.min === 0) {
      yupChain = yupChain.min(this.min)
    } else if (this.required) {
      yupChain = yupChain.min(1)
    }

    if (this.max) {
      yupChain = yupChain.max(this.max)
    }

    return yupChain
  }
}
