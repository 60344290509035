import { i18nExists, i18nTranslate } from '@/i18n'

export default {
  install: (app) => {
    app.config.globalProperties.$i18n = {
      t: i18nTranslate,
      exists: i18nExists
    }
  }
}
