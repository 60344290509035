import TextField from '@/shared/fields/text-field'
import NumberField from '@/shared/fields/number-field'
import RelationToOneField from '@/shared/fields/relation-to-one-field'
import IntegrationService from '@/modules/integration/integration-service'
import TagsField from '@/shared/fields/tags-field'
import EnumeratorField from '@/shared/fields/enumerator-field'
import PostTypeField from '@/shared/fields/post-type-field'
import usePostTypes from '@/modules/post/composables/postTypes'
import usePostPlatforms from '@/modules/post/composables/postPlatforms'

const { allAsOptions: postTypeOptions } = usePostTypes()
const platformOptions = Object.values(usePostPlatforms())

const fields = {
  thumbnail: new TextField('thumbnail', 'Thumbnail'),
  description: new TextField('description', 'Description', {
    filterable: true
  }),
  platform: new EnumeratorField('platform', 'Platform', platformOptions, {
    filterable: true
  }),
  type: new PostTypeField('type', 'Type', postTypeOptions, {
    filterable: true
  }),
  impressions: new NumberField('impressions', 'Impressions', {
    sortable: true,
    filterable: true
  }),
  reach: new NumberField('reach', 'Reach', {
    sortable: true,
    filterable: true
  }),
  likes: new NumberField('likes', 'Likes', {
    sortable: true,
    filterable: true
  }),
  plays: new NumberField('plays', 'Plays', {
    sortable: true,
    filterable: true
  }),
  clicks: new NumberField('clicks', 'Clicks', {
    sortable: true,
    filterable: true
  }),
  saves: new NumberField('saves', 'Saves', {
    sortable: true,
    filterable: true
  }),
  shares: new NumberField('shares', 'Shares', {
    sortable: true,
    filterable: true
  }),
  comments: new NumberField('comments', 'Comments', {
    sortable: true,
    filterable: true
  }),
  integration: new RelationToOneField(
    'integration',
    'Integration',
    IntegrationService.list,
    (v) => v,
    {
      filterable: true
    }
  ),
  tags: new TagsField('tags', 'Tags', { filterable: true })
}

export default class DealModel {
  static get fields() {
    return fields
  }
}
