<template>
  <ViewActive
    :attributes="attributes"
    :board-columns="boardColumns"
    :count="store.count"
    :delete-fn="store.doDestroy"
    :fetch-fn="store.doFetch"
    :loading="store.list.loading"
    :module="module"
    :options-menu-items="optionsMenuItems"
    :records="records"
    :row-height="rowHeight"
    :rows="store.rows"
    :show-sum="showSum"
    :update-fn="store.doUpdate"
    :with-actions="withActions"
    @view-record="handleViewRecordClick"
    @new-record="handleNewRecordClick"
    @delete-record="handleDeleteRecord"
    @edit-record="handleEditRecordClick"
  >
    <template v-for="(_, name) in $slots" #[name]="{ columnData, rowData }">
      <slot :column-data="columnData" :name="name" :row-data="rowData">
        {{ columnData.name }}
      </slot>
    </template>
  </ViewActive>
</template>

<script setup>
import ViewActive from '@/modules/view/components/active/ViewActive.vue'
import { useRouter } from 'vue-router'
import { cloneDeep } from 'lodash'

const router = useRouter()
const props = defineProps({
  module: {
    type: String,
    required: true
  },
  attributes: {
    type: Array,
    required: true
  },
  boardColumns: {
    type: Array,
    default: () => []
  },
  records: {
    type: Object,
    default: () => {}
  },
  store: {
    type: Object,
    required: true
  },
  rowHeight: {
    type: Number,
    default: 57
  },
  showSum: {
    type: Boolean,
    default: false
  },
  optionsMenuItems: {
    type: Array,
    default: () => []
  },
  withActions: {
    type: Boolean,
    default: true
  }
})

const form = defineModel('form', {
  type: Object,
  default: () => {}
})

const handleNewRecordClick = (e) => {
  form.value.record = { ...e }
  form.value.visible = true
}

const handleViewRecordClick = (id) => {
  router.push({ name: `${props.module}-view`, params: { id } })
}

const handleEditRecordClick = (id) => {
  form.value.record = cloneDeep(props.store.records[id])
  form.value.visible = true
}

const handleDeleteRecord = async (id) => {
  await props.store.doDestroy(id)
}
</script>
