<template>
  <div>
    <button
      ref="triggerRef"
      :class="[{ btn__active: isActive }]"
      class="btn btn--secondary btn--pill btn--sm btn--filter"
      @click="handlePanelShow"
    >
      {{ field.label }}
      <span v-if="isValid" class="ml-1"
        >{{ $i18n.t(`shared.filter.${field.type}.${localModel.operator}`) }}
        {{ computedValue }}</span
      >
      <app-icon name="chevron-down"></app-icon>
    </button>
    <Popover ref="panelRef" class="filter-popover" @hide="expanded = false" @show="expanded = true">
      <div class="p-3">
        <div class="flex items-center justify-between mb-1">
          <Select
            v-model="localModel.operator"
            :options="operators"
            class="operator-dropdown mr-4"
            optionLabel="name"
            optionValue="id"
          />
          <app-options-menu
            :items="optionsMenuItems"
            button-icon="dot-horizontal"
            @click-item="handleOptionsMenuClick"
          />
        </div>
        <component
          :is="component"
          v-if="![FilterOperators.NULL, FilterOperators.NOT_NULL].includes(localModel.operator)"
          :id="field.name"
          v-model="localModel.value"
          :operator="localModel.operator"
          class="mt-1"
          v-bind="field.props"
        />
      </div>
    </Popover>
  </div>
</template>
<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import Popover from 'primevue/popover'
import Select from 'primevue/select'
import { cloneDeep, isArray } from 'lodash'

import formattedEuros from '@/shared/utils/formattedEuros'

import FilterTypeText from '@/shared/components/Filter/FilterTypeText.vue'
import FilterTypeNumber from '@/shared/components/Filter/FilterTypeNumber.vue'
import FilterTypeTags from '@/shared/components/Filter/FilterTypeTags.vue'
import FilterTypeDate from '@/shared/components/Filter/FilterTypeDate.vue'
import FilterTypeSelect from '@/shared/components/Filter/FilterTypeSelect.vue'
import FilterTypePostType from '@/shared/components/Filter/FilterTypePostType.vue'
import { format } from 'date-fns'
import { i18nTranslate } from '@/i18n'
import debounce from 'lodash/debounce'
import FilterOperators from '@/enums/FilterOperators'

const props = defineProps({
  field: {
    type: Object,
    default: () => {}
  }
})

const emit = defineEmits(['delete'])

const model = defineModel({
  type: Object,
  default: () => {}
})

const localModel = ref({})

const optionsMenuItems = [
  {
    label: 'Delete',
    type: 'action',
    action: 'delete',
    icon: 'trash'
  }
]

const expanded = ref()
const panelRef = ref()
const triggerRef = ref()

const isValid = computed(() => {
  if ([null, undefined].includes(localModel.value.operator)) return false
  else if ([FilterOperators.NULL, FilterOperators.NOT_NULL].includes(localModel.value.operator))
    return true
  else if (
    FilterOperators.BETWEEN === localModel.value.operator &&
    (!Array.isArray(localModel.value.value) || localModel.value.value.length !== 2)
  )
    return false
  else return ![null, undefined].includes(localModel.value.value)
})

const isActive = computed(() => {
  return expanded.value || isValid.value
})

const component = computed(() => {
  switch (props.field.type) {
    case 'text':
      return FilterTypeText
    case 'number':
      return FilterTypeNumber
    case 'enum':
    case 'entity':
      return FilterTypeSelect
    case 'tags':
      return FilterTypeTags
    case 'date':
    case 'datetime':
      return FilterTypeDate
    case 'post-type':
      return FilterTypePostType
  }
})

const operators = computed(() => {
  return props.field.availableOperators.map((o) => {
    return {
      id: o,
      name: i18nTranslate(`shared.filter.${props.field.type}.${o}`)
    }
  })
})

const computedValue = computed(() => {
  switch (props.field.type) {
    case 'text':
      return model.value.value
    case 'number':
      if (model.value.operator === '$between') {
        const firstValue = model.value.value[0] ?? undefined
        const secondValue = model.value.value[1] ?? undefined

        if (!firstValue) return
        if (!secondValue) return

        const currencyString = `${formattedEuros(firstValue)} and ${formattedEuros(secondValue)}`
        const normalString = `${firstValue} and ${secondValue}`

        return props.field.props?.mode === 'currency' ? currencyString : normalString
      } else
        return props.field.props?.mode === 'currency'
          ? formattedEuros(model.value.value)
          : model.value.value
    case 'date':
      if (model.value.operator === '$between') {
        const firstValue = model.value.value[0] ?? undefined
        const secondValue = model.value.value[1] ?? undefined
        return model.value.operator === '$between'
          ? `${format(firstValue, 'yyyy-MM-dd')} and ${format(secondValue, 'yyyy-MM-dd')}`
          : `${format(firstValue, 'yyyy-MM-dd')}`
      } else {
        return model.value.value
      }
    default:
      return isArray(model.value.value)
        ? model.value.value.map((i) => i.name).join(', ')
        : model.value.value?.name
  }
})

const handlePanelShow = async (event) => {
  await panelRef.value.show(event)
}

const handlePanelHide = async () => {
  await panelRef.value.hide()
}

const handleOptionsMenuClick = ({ action }) => {
  emit('delete')
}

watch(
  localModel,
  debounce(() => {
    if (
      isValid.value &&
      (JSON.stringify(localModel.value.value) !== JSON.stringify(model.value.value) ||
        JSON.stringify(localModel.value.operator) !== JSON.stringify(model.value.operator))
    ) {
      model.value = {
        availableOperators: cloneDeep(localModel.value.availableOperators),
        operator: cloneDeep(localModel.value.operator),
        defaultOperator: cloneDeep(localModel.value.defaultOperator),
        value: cloneDeep(localModel.value.value),
        defaultValue: cloneDeep(localModel.value.defaultValue),
        query: props.field.buildFilterQuery(
          props.field.name,
          localModel.value.operator,
          localModel.value.value
        )
      }
    }
  }, 500),
  { deep: true }
)

watch(
  model,
  debounce((newValue, oldValue) => {
    if (
      JSON.stringify(newValue.value) !== JSON.stringify(oldValue.value) ||
      JSON.stringify(newValue.operator) !== JSON.stringify(oldValue.operator)
    ) {
      localModel.value = cloneDeep(newValue)
    }
  }),
  { deep: true }
)

onMounted(async () => {
  localModel.value = cloneDeep(model.value)
  if (model.value.expanded) {
    setTimeout(() => {
      triggerRef.value.dispatchEvent(new MouseEvent('click'))
    }, 50)
  }
})
</script>
