const DashboardPage = () => import('@/modules/dashboard/pages/DashboardPage.vue')

export default [
  {
    name: 'dashboard',
    path: 'dashboard',
    component: DashboardPage,
    meta: { auth: true, emailVerified: true, tenant: true, init: true }
  }
]
