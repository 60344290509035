<template>
  <div :class="$style.content">
    <slot name="main"></slot>
    <button
      v-tooltip.left="sidebarCollapsed ? 'Expand sidebar' : 'Collapse sidebar'"
      :class="[$style.sidebarToggle, { [$style.sidebarToggleCollapsed]: sidebarCollapsed }]"
      type="button"
      @click="layoutStore.toggleSidebarCollapsed()"
    >
      <app-icon :name="sidebarCollapsed ? 'chevron-left' : 'chevron-right'" />
    </button>
  </div>
  <transition :name="!sidebarCollapsed ? 'slide-left' : 'slide-right'">
    <div
      v-if="!sidebarCollapsed"
      :class="[$style.sidebar, { [$style.sidebarCollapsed]: sidebarCollapsed }]"
    >
      <div class="px-6 py-4 h-full relative">
        <slot name="sidebar"></slot>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { computed } from 'vue'
import useLayoutStore from '@/modules/layout/store'

const layoutStore = useLayoutStore()

const sidebarCollapsed = computed(() => layoutStore.sidebarCollapsed)
</script>

<style lang="scss" module>
.content {
  @apply grow flex flex-col relative;
}

.sidebar {
  @apply basis-full border-l border-gray-15 max-w-xl sticky top-0;

  [data-pc-section='navcontainer'] {
    @apply z-30;
  }
}

.sidebarCollapsed {
  @apply basis-0;
}

.sidebarToggle {
  @apply bg-white border border-gray-15 h-6 w-6 rounded-lg flex items-center justify-center absolute right-0 top-0 -mr-3 mt-4 z-10;
  &:hover {
    @apply bg-purple-10;
  }
}

.sidebarToggleCollapsed {
  @apply -mr-1;
}
</style>
