export default [
  {
    name: 'deal-list',
    path: 'deals',
    component: () => import('@/modules/deal/pages/DealListPage.vue'),
    meta: { auth: true, emailVerified: true, tenant: true, init: true }
  },
  {
    name: 'deal-view',
    path: 'deals/:id',
    component: () => import('@/modules/deal/pages/DealViewPage.vue'),
    meta: { auth: true, emailVerified: true, tenant: true, init: true },
    props: true
  }
]
