<template>
  <div :class="$style.board">
    <ViewTypeBoardColumn
      v-for="column of computedColumns"
      :key="column"
      :column="column"
      :loading="loading"
      :module="module"
      :show-sum="showSum"
      :update-fn="updateFn"
      @view-record="$emit('view-record', $event)"
      @new-record="$emit('new-record', $event)"
    />
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import ViewTypeBoardColumn from '@/modules/view/components/type/board/ViewTypeBoardColumn.vue'
import { filtersAreDifferent } from '@/shared/filter/different-util'
import { cloneDeep } from 'lodash'

const props = defineProps({
  module: {
    type: String,
    required: true
  },
  activeView: {
    type: Object,
    default: () => {}
  },
  attributes: {
    type: Array,
    default: () => []
  },
  boardColumns: {
    type: Array,
    default: () => []
  },
  records: {
    type: Object,
    default: () => {}
  },
  optionsMenuItems: {
    type: Array,
    default: () => []
  },
  fetchFn: {
    type: Function,
    default: () => {}
  },
  updateFn: {
    type: Function,
    default: () => {}
  },
  showSum: {
    type: Boolean,
    default: false
  }
})

defineEmits(['view-record', 'new-record'])

const loading = ref(false)
const computedColumns = computed(() => {
  return props.boardColumns.map((column) => {
    return {
      id: column.id,
      name: column.name,
      items: Object.values(props.records).filter((r) => r.status === column.id)
    }
  })
})

const fetchRecords = async () => {
  loading.value = true
  await props.fetchFn(props.activeView)
  loading.value = false
}

onMounted(async () => {
  await fetchRecords()
})

const watchableView = computed(() => {
  const { sort, filter, query } = cloneDeep(props.activeView)

  return {
    sort,
    filter,
    query
  }
})

watch(
  watchableView,
  async (newValue, oldValue) => {
    if (
      JSON.stringify(oldValue.sort) !== JSON.stringify(newValue.sort) ||
      filtersAreDifferent(newValue.filter, oldValue.filter) ||
      newValue.query !== oldValue.query
    ) {
      await fetchRecords()
    }
  },
  { deep: true }
)
</script>

<style lang="scss" module>
.board {
  @apply flex basis-full -mx-2.5;
}
</style>
