<template>
  <div
    class="focus:ring-0 focus:outline-none"
    tabindex="0"
    @keydown.up="handleKeyPressUp"
    @keydown.down="handleKeyPressDown"
    @keydown.esc.stop="blur"
    @keydown.enter="handleKeyPressEnter"
  >
    <div class="px-4 py-3">
      <div v-if="!model || !model.length" class="text-sm text-gray-50 font-normal">
        {{ placeholder }}
      </div>
      <div v-else class="list-none inline-flex items-center flex-wrap gap-1 -mx-0.5">
        <PostType
          v-for="(item, index) of model"
          :key="item"
          :post="{ platform: item.group, type: item.id }"
          :removable="multiple"
          @remove="model.splice(index, 1)"
        />
      </div>
    </div>
    <hr class="border-gray-15" />
    <ul :class="$style.itemSelectorList">
      <component
        :is="option.component"
        v-for="option of computedOptions"
        :key="option.id"
        v-model="model"
        :class="[
          $style.itemSelectorListItem,
          { [$style.itemSelectorListItem__hover]: option.id === hovered },
          {
            [$style.itemSelectorListItem__active]: option.id === model
          }
        ]"
        :hovered="option.id === hovered"
        :item="option"
        @hovered="handleHoverItem"
        @selected="handleSelectItem"
      ></component>
    </ul>
  </div>
</template>
<script setup>
import PostType from '@/modules/post/components/PostType.vue'
import { computed, reactive, ref, watch } from 'vue'
import TreeSelectInputGroup from '@/shared/components/Form/TreeSelectInput/TreeSelectInputGroup.vue'
import TreeSelectInputOption from '@/shared/components/Form/TreeSelectInput/TreeSelectInputOption.vue'

const props = defineProps({
  options: {
    type: Array,
    required: true
  },
  placeholder: {
    type: String,
    default: 'Select option'
  },
  multiple: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['query-change', 'blur'])

const model = defineModel({
  type: [Array, Object, null],
  required: true
})
const query = ref('')
const hovered = ref(null)

const computedOptions = computed(() => {
  return props.options.map((o) => {
    return {
      ...o,
      component: o.options ? TreeSelectInputGroup : TreeSelectInputOption
    }
  })
})

const handleSelectItem = (item) => {
  if (props.multiple) {
    if (model.value === null) model.value = reactive([item])
    else model.value.push(item)
  } else {
    model.value = item
    blur()
  }
}

const handleHoverItem = (item) => {
  hovered.value = item.id
}

const handleKeyPressUp = () => {
  if (hovered.value || hovered.value === '') {
    const index = computedOptions.value.findIndex((i) => i.id === hovered.id)
    hovered.value =
      index > 0 ? computedOptions.value[index - 1].id : computedOptions.value[index].id
  }
}

const handleKeyPressDown = () => {
  if (!hovered.value) {
    hovered.value = computedOptions.value[0].id
  } else {
    const index = computedOptions.value.findIndex((i) => i.value === hovered.value)
    if (index === computedOptions.value.length - 1) {
      hovered.value = computedOptions.value[index].id
    } else {
      hovered.value = computedOptions.value[index + 1].id
    }
  }
}

watch(query, (newValue) => {
  emit('query-change', newValue)
})

const blur = async () => {
  emit('blur')
}

const handleKeyPressEnter = () => {
  if (hovered.value !== 'custom') {
    model.value = hovered.value
    blur()
  }
}
</script>

<style lang="scss" module>
ul.itemSelectorList {
  @apply max-h-56 overflow-auto p-2;

  li.itemSelectorListItem {
    @apply text-sm text-gray-100 px-2 h-8 flex items-center cursor-pointer rounded-md;
    &.itemSelectorListItem__hover {
      @apply bg-purple-10;
    }

    &.itemSelectorListItem__active {
      @apply text-purple-100;
    }
  }
}
</style>
