<template>
  <div :class="$style.filterTypeDate">
    <DatePicker v-model="computedValue" :selection-mode="selectionMode" inline />
  </div>
</template>
<script setup>
import { computed, ref, watch } from 'vue'
import DatePicker from 'primevue/datepicker'
import { cloneDeep, isArray } from 'lodash'
import { format } from 'date-fns'

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  operator: {
    type: String,
    required: true
  }
})

const model = defineModel({
  type: [String, Array, null],
  required: true
})

const arrayValue = ref(isArray(model.value) ? model.value : [])

const computedValue = computed({
  get() {
    if (selectionMode.value === 'range') {
      return arrayValue.value.map((i) => (i ? new Date(i) : null))
    } else {
      return model.value ? new Date(model.value) : null
    }
  },
  set(v) {
    if (selectionMode.value === 'range') {
      arrayValue.value = v.map((i) => (i ? format(i, 'yyyy-MM-dd') : null))
    } else {
      model.value = format(v, 'yyyy-MM-dd')
    }
  }
})

const selectionMode = computed(() => (props.operator === '$between' ? 'range' : 'single'))

watch(
  () => props.operator,
  () => {
    if (props.operator === '$between' && model.value) {
      arrayValue.value[0] = cloneDeep(model.value)
    } else if (arrayValue.value[0]) {
      model.value = cloneDeep(arrayValue.value[0])
      arrayValue.value = []
    }
  }
)

watch(
  arrayValue,
  (newValue) => {
    if (newValue[0] && newValue[1]) {
      model.value = newValue
    }
  },
  { deep: true }
)
</script>

<style lang="scss" module>
.filterTypeDate {
  [data-pc-section='panel'],
  [data-pc-section='header'] {
    border: 0 !important;
  }
}
</style>
