import sharedGetters from '@/shared/store/getters'

export default {
  ...sharedGetters(),
  connectedIntegrations: (state) => {
    return Object.values(state.records).filter((i) => i.status && i.status !== 'inactive')
  },
  disconnectedIntegrations: (state) => {
    return Object.values(state.records).filter((i) => i.status && i.status === 'inactive')
  }
}
