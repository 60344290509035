<template>
  <div :class="$style.borderCollapsed" class="w-full basis-full flex flex-col overflow-auto">
    <div
      class="bg-gray-5 w-full h-8 shrink-0 px-6 flex items-center justify-between text-sm font-medium text-gray-80 border-l border-gray-15 relative"
    >
      {{ format(activeDate, 'MMMM yyyy') }}
      <div class="flex items-center">
        <button
          class="btn btn--secondary btn--sm !h-6 !px-0 !w-6 !rounded mr-1"
          type="button"
          @click="emit('active-date-prev-month')"
        >
          <app-icon class="h-4 !mx-0" name="chevron-left" />
        </button>
        <button
          :disabled="isSameMonth(activeDate, new Date())"
          class="btn btn--secondary btn--sm"
          type="button"
          @click="emit('active-date-set-today')"
        >
          Today
        </button>
        <button
          class="btn btn--secondary btn--sm !h-6 !px-0 !w-6 !rounded ml-1"
          type="button"
          @click="emit('active-date-next-month')"
        >
          <app-icon class="h-4 !mx-0" name="chevron-right" />
        </button>
      </div>
    </div>
    <div
      :class="$style.borderCollapsed"
      class="flex items-center bg-gray-5 h-8 shrink-0 w-full relative font-medium text-xs text-gray-60 text-center"
    >
      <div class="grow grid grid-cols-7">
        <div>Mon</div>
        <div>Tue</div>
        <div>Wed</div>
        <div>Thu</div>
        <div>Fri</div>
        <div>Sat</div>
        <div>Sun</div>
      </div>
    </div>
    <div class="grid grid-cols-7 grid-rows-6 grow text-xs text-right">
      <div v-for="p in daystoPrepend" :class="$style.borderCollapsed">
        <div class="p-2 text-gray-20">
          {{ getDaysInMonth(addMonths(activeDate, -1)) - p }}
        </div>
      </div>
      <div v-for="d in units" :key="d" :class="$style.borderCollapsed" class="flex flex-col">
        <div class="p-2 flex flex-col basis-full">
          <div :class="{ 'text-purple-100': isSameDay(d, new Date()) }" class="text-gray-100 mb-2">
            {{ format(d, 'd') }}
          </div>
          <ViewTypeCalendarDraggable
            v-model="model"
            :activeView="activeView"
            :date="format(d, 'y-MM-dd')"
            :module="module"
            @item-dragged="emit('item-dragged', $event)"
            @item-clicked="emit('item-clicked', $event)"
          />
        </div>
      </div>
      <div v-for="p in daystoAppend" :class="$style.borderCollapsed">
        <div class="p-2 text-gray-20">{{ p + 1 }}</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  addDays,
  addMonths,
  differenceInDays,
  endOfMonth,
  format,
  getDaysInMonth,
  isBefore,
  isSameDay,
  isSameMonth,
  startOfMonth,
  startOfWeek
} from 'date-fns'
import ViewTypeCalendarDraggable from '@/modules/view/components/type/calendar/ViewTypeCalendarDraggable.vue'
import { computed } from 'vue'

const props = defineProps({
  module: {
    type: String,
    required: true
  },
  activeView: {
    type: Object,
    required: true
  },
  activeDate: {
    type: [String, Date],
    required: true
  }
})

const model = defineModel({ type: Object, required: true })

const emit = defineEmits([
  'active-date-prev-month',
  'active-date-next-month',
  'active-date-set-today',
  'item-dragged',
  'item-clicked'
])

const units = computed(() => {
  let ranges = []
  let startOfRange = addDays(startOfMonth(props.activeDate), -1)
  let endOfRange = addDays(endOfMonth(props.activeDate), -1)

  let currentDate = startOfRange

  while (isBefore(currentDate, endOfRange) || isSameDay(currentDate, endOfRange)) {
    currentDate = addDays(currentDate, 1)
    ranges.push(currentDate)
  }
  return ranges
})

const daystoPrepend = computed(() => {
  const startOfMonthV = startOfMonth(props.activeDate)
  const startOfFirstWeek = startOfWeek(startOfMonthV)
  const daysToFirstDay = differenceInDays(startOfMonthV, startOfFirstWeek)
  return Array.from(new Array(daysToFirstDay).keys())
})

const daystoAppend = computed(() => {
  return Array.from(
    new Array(42 - getDaysInMonth(props.activeDate) - daystoPrepend.value.length).keys()
  )
})
</script>

<style lang="scss" module>
.borderCollapsed {
  box-shadow:
    1px 0 0 0 #e8e8e9,
    0 1px 0 0 #e8e8e9,
    1px 1px 0 0 #e8e8e9,
    /* Just to fix the corner */ 1px 0 0 0 #e8e8e9 inset,
    0 1px 0 0 #e8e8e9 inset;
}
</style>
