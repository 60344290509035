<template>
  <div class="form-errors">
    The following errors have been found
    <ul>
      <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'FormErrors',
  props: {
    errors: {
      type: Array,
      default: () => []
    }
  }
}
</script>
