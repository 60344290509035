<template>
  <div>
    <div :class="$style.pageHeader">
      <div class="flex items-center">
        <div v-if="!showToolbar" :key="breadcrumb.label">
          <router-link :class="$style.pageHeaderBreadcrumbItem" :to="breadcrumb.to">
            {{ breadcrumb.text }}
          </router-link>
          <span class="text-gray-70 mx-2">/</span>
        </div>
        <slot name="title">
          <div :class="$style.pageHeaderTitle">
            {{ title }}
          </div>
        </slot>
      </div>
      <div v-if="showToolbar" :class="$style.pageHeaderToolbar">
        <page-filters
          v-if="mounted && activeView"
          :module="module"
          :show-filter="showFilter"
          :show-search="showSearch"
          :show-sort="showSort && activeView.type !== 'calendar'"
        />
        <slot name="new-record">
          <button v-if="showNew" class="btn btn--primary ml-2" @click="$emit('new-record')">
            <app-icon class="stroke-white" name="plus" />
            New {{ module }}
          </button>
        </slot>
      </div>
      <button
        v-else
        class="btn btn--shadow !h-6 !px-0 !w-6 !rounded"
        @click="$emit('close-record')"
      >
        <app-icon class="!mr-0" color="gray-60" name="close" />
      </button>
    </div>
    <div id="teleport-page-filters" />
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import useViewStore from '@/modules/view/store'
import PageFilters from '@/shared/components/Page/PageFilters.vue'

const viewStore = useViewStore()

const props = defineProps({
  title: {
    type: String,
    default: 'Page'
  },
  module: {
    type: String,
    default: null
  },
  showToolbar: {
    type: Boolean,
    default: true
  },
  showNew: {
    type: Boolean,
    default: true
  },
  showSort: {
    type: Boolean,
    default: true
  },
  showFilter: {
    type: Boolean,
    default: true
  },
  showSearch: {
    type: Boolean,
    default: true
  }
})

defineEmits(['new-record', 'close-record'])

const activeView = computed(() => viewStore.moduleActiveView(props.module))
const breadcrumb = computed(() => {
  return {
    text: activeView.value?.name,
    to: { name: `${props.module}-list`, params: {} }
  }
})

const mounted = ref(false)

onMounted(() => {
  mounted.value = true
})
</script>

<style lang="scss" module>
.pageHeader {
  @apply h-18 flex items-center justify-between px-6 w-full border-b shrink-0 border-gray-15;

  .pageHeaderTitle,
  .pageHeaderBreadcrumbItem {
    @apply text-lg font-medium text-gray-100;
  }

  .pageHeaderBreadcrumbItem {
    @apply text-lg font-medium text-gray-70 transition-colors cursor-pointer no-underline;
    &:hover {
      @apply text-gray-90;
    }
  }

  .pageHeaderToolbar {
    @apply flex items-center;
  }
}
</style>
