import NProgress from 'nprogress'
import '@/assets/scss/_progress-bar.scss'

NProgress.configure({ showSpinner: false })

export default class ProgressBar {
  static start() {
    NProgress.start()
  }

  static done() {
    NProgress.done()
  }
}
