import sharedActions from '@/shared/store/actions'
import IntegrationService from '@/modules/integration/integration-service'
import Errors from '@/shared/error/errors'
import authAxios from '@/shared/axios/auth-axios'

export default {
  ...sharedActions('integration', IntegrationService),
  async doGetMetaLoginUrl() {
    const { data } = await authAxios.get('integration/metaUrl')
    return data
  },
  async doConnectMeta(code) {
    this.list.loading = true
    const { data: responseData } = await authAxios.post('integration/metaCallback', { code })
    for (const record of responseData.data) {
      this.records[record.id] = record
    }
    this.list.loading = false
  },
  async doDisconnect(integration) {
    try {
      const { data: record } = await IntegrationService.update(integration.id, {
        status: 'inactive'
      })
      this.records[record.id] = record
      return record
    } catch (error) {
      Errors.handle(error)
    }
  }
}
