import GenericField from '@/shared/fields/generic-field'
import FilterOperators from '@/enums/FilterOperators'
import { isArray } from 'lodash'

export default class TagsField extends GenericField {
  constructor(name, label, config = {}) {
    super(name, label, config)

    this.required = config.required
    this.placeholder = config.placeholder
    this.hint = config.hint
    this.filterable = config.filterable || false
    this.custom = config.custom || false
  }

  buildFilterQuery(attribute, operator, value) {
    return {
      [attribute]: {
        tag_id: {
          [operator]: isArray(value) ? value.map((i) => i.id) : value?.id
        }
      }
    }
  }

  forFilter() {
    return {
      name: this.name,
      label: this.label,
      custom: this.custom,
      props: {
        fetchFn: this.fetchFn
      },
      defaultValue: [],
      value: [],
      defaultOperator: FilterOperators.IN,
      operator: FilterOperators.IN,
      type: 'tags',
      availableOperators: [
        FilterOperators.IN,
        FilterOperators.NOT_IN,
        FilterOperators.NULL,
        FilterOperators.NOT_NULL
      ],
      buildFilterQuery: this.buildFilterQuery
    }
  }
}
