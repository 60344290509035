<template>
  <transition-group>
    <draggable
      :key="module"
      v-model="model[date]"
      :data-date="date"
      class="h-full text-left"
      item-key="id"
      v-bind="dragOptions"
      @end="handleDragEnd"
    >
      <template #item="{ element }">
        <ViewTypeCalendarDraggableItem
          :item="element"
          class="block mb-2"
          @click="emit('item-clicked', element.id)"
        />
      </template>
    </draggable>
  </transition-group>
</template>
<script setup>
import { ref } from 'vue'
import draggable from 'vuedraggable'
import ViewTypeCalendarDraggableItem from '@/modules/view/components/type/calendar/ViewTypeCalendarDraggableItem.vue'

const props = defineProps({
  module: {
    type: String,
    required: true
  },
  activeView: {
    type: Object,
    required: true
  },
  date: {
    type: [String, null],
    required: true
  }
})

const emit = defineEmits(['item-dragged', 'item-clicked'])
const model = defineModel({ type: Object, required: true })

const dragOptions = ref({
  animation: 200,
  group: `calendar_${props.module}_${props.activeView.id}`,
  disabled: false,
  ghostClass: 'ghost'
})

const handleDragEnd = async (event) => {
  const { to, item } = event
  emit('item-dragged', { id: item.dataset.id, to: to.dataset.date })
}
</script>
