import { app } from '@/main'

export default {
  info(content, options) {
    app.config.globalProperties.$toast.add({
      severity: 'info',
      summary: content,
      life: 3000,
      ...options
    })
  },
  success(content, options) {
    app.config.globalProperties.$toast.add({
      severity: 'success',
      summary: content,
      life: 3000,
      ...options
    })
  },
  error(content, options) {
    app.config.globalProperties.$toast.add({
      severity: 'error',
      summary: content,
      life: 3000,
      ...options
    })
  }
}
