<template>
  <div class="-m-3">
    <TreeSelectInputBase
      v-model="computedValue"
      :multiple="multiple"
      :options="options"
      :placeholder="placeholder"
    />
  </div>
</template>

<script setup>
import { computed, reactive, watch } from 'vue'
import { cloneDeep } from 'lodash'
import TreeSelectInputBase from '@/shared/components/Form/TreeSelectInput/TreeSelectInputBase.vue'

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  operator: {
    type: String,
    required: true
  },
  options: {
    type: Array,
    default: () => []
  },
  placeholder: {
    type: String,
    default: 'Select option'
  }
})

const model = defineModel({
  type: [Array, Object, null],
  required: true
})

const computedValue = computed({
  get() {
    return model.value ?? reactive([])
  },
  set(v) {
    model.value = v
  }
})

const multiple = computed(() => {
  return ['$in', '$notIn'].includes(props.operator) && Array.isArray(computedValue.value)
})

watch(
  () => props.operator,
  (newValue, oldValue) => {
    if (
      ['$in', '$notIn'].includes(newValue) &&
      !['$in', '$notIn'].includes(oldValue) &&
      !Array.isArray(computedValue.value)
    ) {
      model.value = [cloneDeep(model.value)]
    } else if (
      !['$in', '$notIn'].includes(newValue) &&
      ['$in', '$notIn'].includes(oldValue) &&
      model.value[0]
    ) {
      model.value = cloneDeep(model.value[0])
    }
  }
)
</script>
