<template>
  <div class="inline-flex items-center text-xs border border-gray-15 rounded-md p-1">
    <img :alt="post.platform" :src="imageUrl" class="h-3 w-3 mr-1 block" />
    <span>{{ i18nTranslate(`platform.${post.platform}.post_type.${post.type}`) }}</span>
    <button v-if="removable" class="tag-remove" @click="$emit('remove', post)">
      <app-icon name="close-xs"></app-icon>
    </button>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { i18nTranslate } from '@/i18n'

const props = defineProps({
  post: {
    type: Object,
    required: true
  },
  removable: {
    type: Boolean,
    default: false
  }
})

defineEmits(['remove'])

const imageUrl = computed(
  () => new URL(`/src/assets/img/integrations/${props.post.platform}.png`, import.meta.url).href
)
</script>
