import * as yup from 'yup'
import GenericField from '@/shared/fields/generic-field'
import { i18nTranslate } from '@/i18n'
import FilterOperators from '@/enums/FilterOperators'

export default class TextField extends GenericField {
  constructor(name, label, config = {}) {
    super(name, label, config)

    this.placeholder = config.placeholder
    this.hint = config.hint
    this.required = config.required
    this.matches = config.matches
    this.min = config.min
    this.max = config.max
    this.filterable = config.filterable || false
    this.sortable = config.sortable || false
    this.email = config.email
  }

  buildFilterQuery(attribute, operator, value) {
    return {
      [attribute]: {
        [operator]: value
      }
    }
  }

  forPresenter(value) {
    return value
  }

  forFilterPreview(value) {
    if (typeof value === 'object' && value) {
      return value.id
    }
    return value
  }

  forFilter() {
    return {
      name: this.name,
      label: this.label,
      props: {},
      defaultValue: null,
      value: null,
      defaultOperator: FilterOperators.CONTAINS,
      operator: FilterOperators.CONTAINS,
      type: 'text',
      availableOperators: [
        FilterOperators.CONTAINS,
        FilterOperators.NOT_CONTAINS,
        FilterOperators.STARTS_WITH,
        FilterOperators.ENDS_WITH,
        FilterOperators.NULL,
        FilterOperators.NOT_NULL
      ],
      buildFilterQuery: this.buildFilterQuery
    }
  }

  forSort() {
    return {
      name: this.name,
      label: this.label
    }
  }

  forFormInitialValue(value) {
    return value
  }

  forFilterInitialValue(value) {
    return value
  }

  forFormRules() {
    const output = []

    if (this.required) {
      output.push({
        required: Boolean(this.required),
        message: i18nTranslate('validation.mixed.required').replace('{path}', this.label)
      })
    }

    if (this.min || this.min === 0) {
      output.push({
        min: this.min,
        message: i18nTranslate('validation.string.min')
          .replace('{path}', this.label)
          .replace('{min}', this.min)
      })
    }

    if (this.max || this.max === 0) {
      output.push({
        max: this.max,
        message: i18nTranslate('validation.string.max')
          .replace('{path}', this.label)
          .replace('{max}', this.max)
      })
    }

    if (this.matches) {
      output.push({
        pattern: this.matches,
        message: i18nTranslate('validation.mixed.default').replace('{path}', this.label)
      })
    }

    if (this.email) {
      output.push({
        type: 'email',
        message: 'Please input correct email address',
        trigger: ['blur', 'change']
      })
    }

    return output
  }

  forFormCast() {
    let yupChain = yup.string().nullable(true).trim().label(this.label)

    if (this.required) {
      yupChain = yupChain.required()
    }

    if (this.email) {
      yupChain = yupChain.email()
    }

    return yupChain
  }

  forFilterCast() {
    return yup.string().nullable(true).trim().label(this.label)
  }

  forExport() {
    return yup.mixed().label(this.label)
  }

  forImport() {
    let yupChain = yup.string().nullable(true).trim().label(this.label)

    if (this.required) {
      yupChain = yupChain.required()
    }

    if (this.min || this.min === 0) {
      yupChain = yupChain.min(this.min)
    }

    if (this.max) {
      yupChain = yupChain.max(this.max)
    }

    if (this.matches) {
      yupChain = yupChain.matches(/^[0-9]/)
    }

    return yupChain
  }
}
