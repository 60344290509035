import useAuthStore from '@/modules/auth/store'
import useTenantStore from '@/modules/tenant/store'

export default async function ({ to }) {
  if (!to.meta || !to.meta.tenant) {
    return
  }

  const authStore = useAuthStore()
  const tenantStore = useTenantStore()

  if (!tenantStore.hasRows) {
    await tenantStore.doFetch()
  }

  // Clear cached/saved tenant from localStorage
  if (authStore.currentTenant && authStore.currentUser.tenants.length === 0) {
    await authStore.doSetCurrentTenant(null)
  }

  // Ensure user has currentTenant, if not redirect to /new-workspace or /select-workspace
  if (!authStore.currentTenant) {
    if (authStore.currentUser.tenants.length === 1) {
      await authStore.doSetCurrentTenant()
    } else if (
      (!authStore.currentUser.tenants || authStore.currentUser.tenants.length === 0) &&
      to.name !== 'new-workspace'
    ) {
      throw { name: 'new-workspace' }
    } else if (
      to.name !== 'new-workspace' &&
      to.name !== 'select-workspace' &&
      to.name !== 'logout'
    ) {
      throw { name: 'select-workspace' }
    }
  }
}
