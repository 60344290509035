import auth from '@/modules/auth/auth-module'
import onboard from '@/modules/onboard/onboard-module'
import dashboard from '@/modules/dashboard/dashboard-module'
import tenant from '@/modules/tenant/tenant-module'
import task from '@/modules/task/task-module'
import company from '@/modules/company/company-module'
import person from '@/modules/person/person-module'
import deal from '@/modules/deal/deal-module'
import post from '@/modules/post/post-module'
import brand from '@/modules/brand/brand-module'
import integrationPost from '@/modules/integration-post/integration-post-module'
import mediaKit from '@/modules/media-kit/media-kit-module'
import integration from '@/modules/integration/integration-module'
import settings from '@/modules/settings/settings-module'
import user from '@/modules/user/user-module'
import notification from '@/modules/user/user-module'

import shared from '@/shared/shared-module'

export default {
  auth,
  onboard,
  dashboard,
  tenant,
  task,
  company,
  person,
  deal,
  post,
  brand,
  integrationPost,
  mediaKit,
  integration,
  settings,
  user,
  notification,
  shared
}
