import NotificationService from '@/modules/notification/notification-service'
import Errors from '@/shared/error/errors'
import Toast from '@/shared/components/Toast'

export default {
  async doFetch() {
    try {
      this.list.loading = true

      const { data, meta } = await NotificationService.list()

      data.map((record) => {
        this.records[record.id] = record
        if (this.list.ids.indexOf(record.id) === -1) {
          this.list.ids.push(record.id)
        }
      })

      this.count = meta?.total ?? data.length
      this.list.loading = false

      return data
    } catch (error) {
      Errors.handle(error)
    }
  },

  async doFind(id) {
    try {
      const { data: record } = await NotificationService.find(id)

      this.records[record.id] = record
      if (this.list.ids.indexOf(record.id) === -1) {
        this.list.ids.push(record.id)
      }

      this.count = this.count + 1

      return record
    } catch (error) {
      Errors.handle(error)
    }
  },

  async doMarkAsRead(ids) {
    try {
      const { data } = await NotificationService.markAsRead(ids)

      data.map((record) => {
        this.records[record.id] = record
      })

      return data
    } catch (error) {
      Errors.handle(error)
    }
  },

  async doArchive(ids) {
    try {
      const { data } = await NotificationService.archive(ids)

      data.map((record) => {
        delete this.records[record.id]
        const index = this.list.ids.indexOf(record.id)
        this.list.ids.splice(index, 1)
      })

      Toast.info(`Notification${ids.length > 1 ? 's' : ''} archived successfully.`)

      return data
    } catch (error) {
      Errors.handle(error)
    }
  }
}
