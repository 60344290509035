<template>
  <li @click="handleCreate" @mouseover="$emit('hovered', 'new')">
    Create
    <app-tag :tag="{ name: query, color: randomColor }" class="ml-2" />
  </li>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  query: {
    type: String,
    default: null
  },
  createFn: {
    type: Function,
    default: () => {}
  }
})
const emit = defineEmits(['created', 'hovered'])

const randomColor = computed(() => {
  const colors = ['gray', 'purple', 'red', 'green', 'orange', 'pink', 'blue', 'lightBlue']
  return colors[Math.floor(Math.random() * colors.length)]
})

const handleCreate = async () => {
  const tag = await props.createFn({ name: props.query, color: randomColor.value })
  emit('created', tag)
}
</script>
