<template>
  <div v-if="computedItems.length <= 20">
    <div v-for="item of computedItems" :key="item.id">
      <span v-if="item.entity" class="text-gray-60 text-xs px-2 py-1 my-1 block">{{
        $i18n.t(`module.${item.entity}.name`)
      }}</span>
      <SelectInputOption
        :class="[itemClass, { [itemHoveredClass]: item.id === hovered }]"
        :item="item"
        @hovered="(v) => $emit('hovered', v)"
        @selected="(v) => $emit('selected', v)"
      >
        <template #option="{ item: itemFromSlot }">
          <slot :is-hovered="item.id === hovered" :item="itemFromSlot" name="option"></slot>
        </template>
      </SelectInputOption>
    </div>
  </div>
  <VirtualScroller v-else :itemSize="32" :items="computedItems" class="h-40 overflow-auto">
    <template #item="{ item }">
      <span v-if="item.entity" class="text-gray-60 text-xs px-2 py-1 my-1 block">{{
        $i18n.t(`module.${item.entity}.name`)
      }}</span>
      <SelectInputOption
        :class="[itemClass, { [itemHoveredClass]: item.id === hovered }]"
        :item="item"
        @hovered="(v) => $emit('hovered', v)"
        @selected="(v) => $emit('selected', v)"
      >
        <template #option="{ item: itemFromSlot }">
          <slot :is-hovered="item.id === hovered" :item="itemFromSlot" name="option"></slot>
        </template>
      </SelectInputOption>
    </template>
  </VirtualScroller>
</template>
<script setup>
import SelectInputOption from '@/shared/components/Form/SelectInput/SelectInputOption.vue'
import { computed } from 'vue'
import { cloneDeep } from 'lodash'
import VirtualScroller from 'primevue/virtualscroller'

const props = defineProps({
  multipleEntities: {
    type: Boolean,
    default: false
  },
  items: {
    type: Array,
    default: () => []
  },
  itemClass: {
    type: String,
    default: ''
  },
  itemHoveredClass: {
    type: String,
    default: ''
  },
  hovered: {
    type: String,
    default: ''
  }
})

defineEmits(['selected', 'hovered'])

const itemsSortedByEntity = cloneDeep(props.items).sort((a, b) => a.id - b.id)

const computedItems = computed(() => {
  if (!props.multipleEntities) return props.items

  const loadedTitles = {}

  return itemsSortedByEntity.reduce((acc, item) => {
    if (loadedTitles[item.entity]) {
      item.entity = undefined
    } else {
      loadedTitles[item.entity] = true
    }

    acc.push(item)
    return acc
  }, [])
})
</script>
