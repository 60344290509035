import authAxios from '@/shared/axios/auth-axios'

export default class CompanyService {
  static async update(id, data) {
    const response = await authAxios.put(`/company/${id}`, data)

    return response.data
  }

  static async destroy(id) {
    await authAxios.delete(`/company/${id}`)

    return true
  }

  static async create(data) {
    const response = await authAxios.post(`/company`, data)

    return response.data
  }

  static async find(id) {
    const response = await authAxios.get(`/company/${id}`)
    return response.data
  }

  static async list(
    query = undefined,
    filter = undefined,
    sort = undefined,
    perPage = undefined,
    page = undefined
  ) {
    const params = {
      query,
      filter,
      sort,
      perPage,
      page
    }

    const response = await authAxios.get(`/company`, {
      params
    })

    return response.data
  }
}
