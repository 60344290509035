import TextField from '@/shared/fields/text-field'
import RelationToOneField from '@/shared/fields/relation-to-one-field'
import TagsField from '@/shared/fields/tags-field'
import CompanyService from '@/modules/company/company-service'

const fields = {
  name: new TextField('name', 'Name', {
    filterable: true,
    sortable: true
  }),
  email: new TextField('email', 'Email', {
    filterable: true,
    sortable: true,
    email: true
  }),
  phone: new TextField('phone', 'Phone', {
    filterable: true
  }),
  company: new RelationToOneField('company', 'Company', CompanyService.list, (v) => v, {
    sortable: true,
    filterable: true
  }),
  tags: new TagsField('tags', 'Tags', { filterable: true })
}

export default class PersonModel {
  static get fields() {
    return fields
  }
}
