<template>
  <div
    class="flex items-center flex-wrap px-4 py-3 min-h-12 -m-0.5"
    @click.prevent="handleFocusClick"
  >
    <Tag
      v-for="item in selectedItems"
      :key="item.id"
      :removable="true"
      :tag="{ id: item.id, name: item.name ?? item.label, color: item.color ?? null }"
      class="m-0.5"
      @remove="$emit('remove-item', item)"
    />
    <span
      v-if="selectedItems.length"
      ref="querySpanRef"
      :class="$style.selectInputQuery"
      :contenteditable="shouldFilter"
      class="mx-1 my-0.5 text-sm outline-none"
      @input="handleContentEditableInput"
      @keydown.enter.prevent
      >{{ query }}</span
    >
    <input
      ref="queryInputRef"
      v-model="query"
      :class="[$style.selectInputQuery, { '!hidden': selectedItems.length > 0 }]"
      :placeholder="selectedItems.length === 0 ? placeholder : ''"
      class="m-0.5"
      type="text"
    />
  </div>
</template>
<script setup>
import Tag from '@/shared/components/Tag.vue'
import { onMounted, ref } from 'vue'

const props = defineProps({
  multiple: {
    type: Boolean,
    default: false
  },
  shouldFilter: {
    type: Boolean,
    default: false
  },
  selectedItems: {
    type: Array,
    default: () => []
  },
  placeholder: {
    type: String,
    default: ''
  },
  inputClass: {
    type: String,
    default: ''
  }
})

defineEmits(['remove-item'])

const query = defineModel({
  type: String,
  default: null
})

const queryInputRef = ref(null)
const querySpanRef = ref(null)

const handleFocusClick = () => {
  if (props.selectedItems.length) {
    querySpanRef.value.focus()
  } else {
    queryInputRef.value.focus()
  }
}

const handleContentEditableInput = (event) => {
  query.value = event.target.innerText
}

onMounted(() => {
  handleFocusClick()
})
</script>

<style lang="scss" module>
input[type='text'].selectInputQuery {
  @apply border-none p-0 h-4 rounded-none;

  &::placeholder {
    @apply text-gray-50;
  }
}
</style>
