import '@/assets/scss/index.scss'

import { createApp } from 'vue'
import { createStore } from './store'
import { createRouter } from './router'

import * as Sentry from '@sentry/vue'

import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'
import { plugin as VueTippy } from 'vue-tippy'
import 'tippy.js/animations/shift-away-subtle.css'
import Tooltip from 'primevue/tooltip'
import Aura from '@/presets/aura'

import App from './App.vue'

import modules from '@/modules'

import Echo from '@/plugins/echo'
import I18n from '@/plugins/i18n'

const store = createStore()
const router = createRouter()
const app = createApp(App)

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    tunnel: `${import.meta.env.VITE_BACKEND_URL}/sentry-tunnel`,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/api\.tamable\.app/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

app.use(store)
app.use(router)

// init plugins
app.use(Echo)
app.use(I18n)
app.use(PrimeVue, {
  unstyled: true,
  pt: Aura
})
app.use(ToastService)
app.use(ConfirmationService)
app.use(VueTippy, {
  component: 'tippy',
  defaultProps: {
    duration: 100,
    delay: [100, 0],
    animation: 'shift-away-subtle'
  },
  directive: 'tippy'
})
app.directive('tooltip', Tooltip)

app.mount('#app')

const exists = (el) => Boolean(el)
Object.keys(modules)
  .map((key) => modules[key].components)
  .filter(exists)
  .forEach((components) => {
    Object.keys(components).forEach((name) => {
      app.component(name, components[name])
    })
  })

export { app }
