<template>
  <div class="-m-3">
    <SelectInputBase
      v-model="computedValue"
      :input-placeholder="placeholder"
      :items="computedItems"
      :loading="loading"
      :multiple="multiple"
      @query-change="search"
    />
  </div>
</template>

<script setup>
import { computed, reactive, watch } from 'vue'
import SelectInputBase from '@/shared/components/Form/SelectInput/SelectInputBase.vue'
import { cloneDeep } from 'lodash'
import { useDebouncedSearch } from '@/shared/composables/debouncedSearch'

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  operator: {
    type: String,
    required: true
  },
  options: {
    type: Array,
    default: () => []
  },
  multiple: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    default: 'Select option'
  },
  fetchFn: {
    type: Function,
    default: () => {
      return {
        data: []
      }
    }
  },
  async: {
    type: Boolean,
    default: false
  }
})

const model = defineModel({
  type: [Array, Object, null],
  required: true
})

const computedValue = computed({
  get() {
    return model.value ?? reactive([])
  },
  set(v) {
    model.value = v
  }
})

const multiple = computed(() => {
  return ['$in', '$notIn'].includes(props.operator) && Array.isArray(computedValue.value)
})

const { loading, items, search } = useDebouncedSearch(props.fetchFn)

const computedItems = computed(() => {
  return props.async ? items.value : props.options
})

watch(
  () => props.operator,
  (newValue, oldValue) => {
    if (['$in', '$notIn'].includes(newValue) && !['$in', '$notIn'].includes(oldValue)) {
      computedValue.value = [cloneDeep(computedValue.value)]
    } else if (
      !['$in', '$notIn'].includes(newValue) &&
      ['$in', '$notIn'].includes(oldValue) &&
      computedValue.value[0]
    ) {
      computedValue.value = cloneDeep(computedValue.value[0])
    }
  }
)
</script>
