<template>
  <div>
    <div class="mb-6">
      <label class="block font-normal text-xs" for="password-input">{{ passwordLabel }}</label>
      <input
        id="password-input"
        :value="password"
        class="mt-1"
        placeholder="Enter your password"
        type="password"
        @input="$emit('update:password', $event.target.value)"
      />
    </div>
    <div class="mb-6">
      <label class="block font-normal text-xs" for="password_confirmation-input">{{
        passwordConfirmationLabel
      }}</label>
      <input
        id="password_confirmation-input"
        :value="passwordConfirmation"
        class="mt-1"
        placeholder="Re-enter your password"
        type="password"
        @blur="blured = true"
        @input="$emit('update:passwordConfirmation', $event.target.value)"
      />
    </div>
    <div v-if="!isValid && blured" class="form-errors">Passwords do not match</div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'

const props = defineProps({
  passwordLabel: {
    type: String,
    default: 'Password'
  },
  passwordConfirmationLabel: {
    type: String,
    default: 'Confirm password'
  },
  password: {
    type: String,
    default: null
  },
  passwordConfirmation: {
    type: String,
    default: null
  }
})

const emit = defineEmits([
  'update:password',
  'update:passwordConfirmation',
  'update:passwordIsValid',
  'password-valid-update'
])

const blured = ref(false)

const isValid = computed(() => props.password === props.passwordConfirmation)

watch(isValid, (v) => emit('password-valid-update', v))
</script>
