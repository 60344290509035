<template>
  <div class="-m-3">
    <SelectInputBase
      v-model="computedValue"
      :input-placeholder="placeholder"
      :items="Object.values(tagStore.records)"
      :multiple="true"
    />
  </div>
</template>

<script setup>
import { computed, reactive } from 'vue'
import SelectInputBase from '@/shared/components/Form/SelectInput/SelectInputBase.vue'
import useTagStore from '@/modules/tag/store'

const tagStore = useTagStore()

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  multiple: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    default: 'Select tags'
  }
})

const model = defineModel({
  type: [Array, null],
  required: true
})

const computedValue = computed({
  get() {
    return model.value ?? reactive([])
  },
  set(v) {
    model.value = v
  }
})
</script>
