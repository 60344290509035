import TextField from '@/shared/fields/text-field'
import TagsField from '@/shared/fields/tags-field'
import RelationToManyField from '@/shared/fields/relation-to-many-field'
import BrandService from '@/modules/brand/brand-service'

const fields = {
  name: new TextField('name', 'Name', {
    filterable: true,
    sortable: true
  }),
  email: new TextField('email', 'Email', {
    filterable: true,
    sortable: true,
    email: true
  }),
  phone: new TextField('phone', 'Phone', {
    filterable: true
  }),
  vat: new TextField('vat', 'VAT', {
    filterable: true
  }),
  website: new TextField('website', 'Website', {
    filterable: true
  }),
  brands: new RelationToManyField('brands', 'Brands', BrandService.list, (v) => v, {
    filterable: true
  }),
  tags: new TagsField('tags', 'Tags', { filterable: true })
}

export default class CompanyModel {
  static get fields() {
    return fields
  }
}
