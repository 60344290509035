import facebookLogo from '@/assets/img/integrations/facebook.png'
import instagramLogo from '@/assets/img/integrations/instagram.png'
import threadsLogo from '@/assets/img/integrations/threads.png'
import linkedinLogo from '@/assets/img/integrations/linkedin.png'
import spotifyLogo from '@/assets/img/integrations/spotify.png'
import substackLogo from '@/assets/img/integrations/substack.png'
import tiktokLogo from '@/assets/img/integrations/tiktok.png'
import youtubeLogo from '@/assets/img/integrations/youtube.png'
import twitterLogo from '@/assets/img/integrations/twitter.png'

export default () => {
  return {
    facebook: {
      id: 'facebook',
      name: 'Facebook',
      logo: facebookLogo,
      enabled: true
    },
    instagram: {
      id: 'instagram',
      name: 'Instagram',
      logo: instagramLogo,
      enabled: true,
      expanded: false
    },
    threads: {
      id: 'threads',
      name: 'Threads',
      logo: threadsLogo,
      enabled: false
    },
    linkedin: {
      id: 'linkedin',
      name: 'LinkedIn',
      logo: linkedinLogo,
      enabled: false
    },
    spotify: {
      id: 'spotify',
      name: 'Spotify',
      logo: spotifyLogo,
      enabled: false
    },
    substack: {
      id: 'substack',
      name: 'Substack',
      logo: substackLogo,
      enabled: false
    },
    tiktok: {
      id: 'tiktok',
      name: 'Tiktok',
      logo: tiktokLogo,
      enabled: false
    },
    youtube: {
      id: 'youtube',
      name: 'YouTube',
      logo: youtubeLogo,
      enabled: false
    },
    x: {
      id: 'x',
      name: 'X',
      logo: twitterLogo,
      enabled: false
    }
  }
}
