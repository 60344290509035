import DealService from '@/modules/deal/deal-service'
import TextField from '@/shared/fields/text-field'
import EnumeratorField from '@/shared/fields/enumerator-field'
import { i18nTranslate } from '@/i18n'
import PostStages from '@/enums/PostStages'
import RelationToOneField from '@/shared/fields/relation-to-one-field'
import TagsField from '@/shared/fields/tags-field'
import DateTimeField from '@/shared/fields/date-time-field'
import PostTypeField from '@/shared/fields/post-type-field'
import usePostTypes from '@/modules/post/composables/postTypes'
import usePostPlatforms from '@/modules/post/composables/postPlatforms'

const { allAsOptions: postTypeOptions } = usePostTypes()
const platformOptions = Object.values(usePostPlatforms())

const fields = {
  name: new TextField('name', 'Name', {
    sortable: true,
    filterable: true
  }),
  description: new TextField('description', 'Description', {
    filterable: true
  }),
  status: new EnumeratorField(
    'status',
    'Status',
    Object.values(PostStages).map((s) => {
      return {
        id: s,
        name: i18nTranslate(`module.post.status.${s}`)
      }
    }),
    { sortable: true, filterable: true }
  ),
  platform: new EnumeratorField('platform', 'Platform', platformOptions, {
    filterable: true
  }),
  type: new PostTypeField('type', 'Type', postTypeOptions, {
    filterable: true
  }),
  deal: new RelationToOneField('deal', 'Deal', DealService.list, (v) => v, {
    sortable: true,
    filterable: true
  }),
  tags: new TagsField('tags', 'Tags', { filterable: true }),
  send_proposal_due_at: new DateTimeField('send_proposal_due_at', 'Send proposal', {
    filterable: true,
    calendable: true
  }),
  send_content_due_at: new DateTimeField('send_content_due_at', 'Send content', {
    filterable: true,
    calendable: true
  }),
  publish_content_due_at: new DateTimeField('publish_content_due_at', 'Publish content', {
    filterable: true,
    calendable: true
  })
}

export default class PostModel {
  static get fields() {
    return fields
  }

  static get defaultDateName() {
    return fields.publish_content_due_at.name
  }

  static get defaultDateLabel() {
    return fields.publish_content_due_at.label
  }
}
