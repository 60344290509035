<template>
  <div ref="rootRef" class="basis-full w-full flex flex-col relative">
    <transition-group>
      <DataTable v-if="shouldShowSkeleton" :value="new Array(10)">
        <Column field="">
          <template #header>
            <Skeleton></Skeleton>
          </template>
          <template #body>
            <Skeleton></Skeleton>
          </template>
        </Column>
        <Column field="">
          <template #header>
            <Skeleton></Skeleton>
          </template>
          <template #body>
            <Skeleton></Skeleton>
          </template>
        </Column>
        <Column field="">
          <template #header>
            <Skeleton></Skeleton>
          </template>
          <template #body>
            <Skeleton></Skeleton>
          </template>
        </Column>
        <Column field="">
          <template #header>
            <Skeleton></Skeleton>
          </template>
          <template #body>
            <Skeleton></Skeleton>
          </template>
        </Column>
      </DataTable>
      <slot v-else-if="!count">
        <div class="h-64 w-full flex items-center justify-center">
          <p class="text-sm text-gray-80 mr-4">No records were found, create the first one</p>
          <button class="btn btn--primary" @click="emit('new-record')">
            <app-icon class="stroke-white" name="plus"></app-icon>
            New
          </button>
        </div>
      </slot>
      <DataTable
        v-else
        v-model:selection="selected"
        :value="rows"
        :virtualScrollerOptions="{
          lazy: true,
          itemSize: rowHeight,
          onScroll: handleScroll,
          numToleratedItems: 25
        }"
        class="datatable"
        scroll-height="flex"
        scrollable
      >
        <Column header-style="width: 3rem" selection-mode="multiple"></Column>
        <Column
          v-for="column of columns"
          :key="column.id"
          :field="column.id"
          :header="column.label"
        >
          <template #body="{ data }">
            <slot
              v-if="data[column.id] !== null"
              :column-data="data[column.id]"
              :name="`table_column_${column.id}`"
              :row-data="data"
            >
              {{ data[column.id] }}
            </slot>
            <span v-else>-</span>
          </template>
        </Column>
        <Column v-if="withActions" field="options" header="">
          <template #body="{ data }">
            <div class="flex justify-end">
              <app-options-menu
                :id="data.id"
                :items="computedOptionsMenuItems"
                @click-item="handleOptionsMenuClick"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </transition-group>
    <ViewTypeTableBulkActions v-model="selected" :delete-fn="deleteFn" />
  </div>
</template>
<script setup>
import { computed, onBeforeMount, ref, watch } from 'vue'
import DataTable from 'primevue/datatable'
import Skeleton from 'primevue/skeleton'
import Column from 'primevue/column'
import useViewStore from '@/modules/view/store'
import { cloneDeep } from 'lodash'
import { filtersAreDifferent } from '@/shared/filter/different-util'
import ViewTypeTableBulkActions from '@/modules/view/components/type/table/ViewTypeTableBulkActions.vue'

const viewStore = useViewStore()

const props = defineProps({
  module: {
    type: String,
    required: true
  },
  activeView: {
    type: Object,
    default: () => {}
  },
  columns: {
    type: Array,
    default: () => []
  },
  rows: {
    type: Array,
    default: () => []
  },
  rowHeight: {
    type: Number,
    default: 57
  },
  count: {
    type: Number,
    default: 0
  },
  optionsMenuItems: {
    type: Array,
    default: () => []
  },
  fetchFn: {
    type: Function,
    default: () => {}
  },
  deleteFn: {
    type: Function,
    default: () => {}
  },
  withActions: {
    type: Boolean,
    default: true
  }
})
const emit = defineEmits(['new-record', 'edit-record', 'delete-record'])

const defaultMenuItems = [
  {
    label: 'View details',
    type: 'action',
    action: 'view',
    icon: 'eye-open'
  },
  {
    label: 'Quick edit',
    type: 'action',
    action: 'edit',
    icon: 'edit'
  },
  {
    type: 'separator'
  },
  {
    label: 'Delete',
    type: 'action',
    action: 'delete',
    icon: 'trash',
    confirmed: true
  }
]

const selected = ref()
const loading = ref(false)
const rootRef = ref()

const paginator = computed(() => props.activeView.paginator)
const shouldShowSkeleton = computed(() => {
  return loading.value && props.count === 0
})

const computedOptionsMenuItems = computed(() =>
  props.optionsMenuItems.length ? props.optionsMenuItems : defaultMenuItems
)

const handleOptionsMenuClick = ({ action, id }) => {
  switch (action) {
    case 'view':
      emit('view-record', id)
      break
    case 'edit':
      emit('edit-record', id)
      break
    case 'delete':
      emit('delete-record', id)
      break
  }
}

const fetchRecords = async (keepPagination = false) => {
  loading.value = true
  await props.fetchFn(props.activeView, keepPagination)
  loading.value = false
}

const handleScroll = async (event) => {
  if (props.rows.length >= props.count) return

  const h = event.target,
    b = rootRef.value,
    st = 'scrollTop',
    sh = 'scrollHeight'
  const percent = ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100

  if (percent > 90 && !loading.value) {
    const { first, perPage } = paginator.value
    viewStore.setViewPaginatorFirst(props.activeView.id, first + perPage)
    await fetchRecords(true)
  }
}

onBeforeMount(async () => {
  await fetchRecords()
})

const watchableView = computed(() => {
  const { sort, filter, query } = cloneDeep(props.activeView)

  return {
    sort,
    filter,
    query
  }
})

watch(
  watchableView,
  async (newValue, oldValue) => {
    if (
      JSON.stringify(oldValue.sort) !== JSON.stringify(newValue.sort) ||
      filtersAreDifferent(newValue.filter, oldValue.filter) ||
      newValue.query !== oldValue.query
    ) {
      await fetchRecords()
    }
  },
  { deep: true }
)
</script>
