import AuthService from '@/modules/auth/auth-service'
import AuthCurrentTenant from '@/modules/auth/auth-current-tenant'
import UserService from '@/modules/user/user-service'
import Toast from '@/shared/components/Toast'

export default {
  async doFetchCurrentUser() {
    const { data } = await AuthService.me()
    this.currentUser = data
    return data
  },
  async doUpdateCurrentUser(values) {
    const { data } = await UserService.update(this.currentUser.id, values, true)
    this.currentUser = data
    Toast.info('Account updated successfully')
    return data
  },
  async doLogin(email, password) {
    await AuthService.login(email, password, true)
    return this.doFetchCurrentUser()
  },
  async doSignup(email, password, password_confirmation) {
    await AuthService.signup(email, password, password_confirmation)
    return this.doFetchCurrentUser()
  },
  async doLogout() {
    this.currentUser = null
    this.currentTenantId = null
    AuthCurrentTenant.clear()
    return AuthService.logout()
  },
  async doSetCurrentTenant(tenant = {}) {
    let currentTenantId = tenant.id
    if (!currentTenantId) {
      const activeTenants = this.currentUser.tenants.filter((tenant) => tenant.status === 'active')
      currentTenantId = activeTenants[0].id
    }
    AuthCurrentTenant.set(currentTenantId)
    this.currentTenantId = currentTenantId
    return this.doFetchCurrentUser()
  },
  async doFetchCsrfToken() {
    await AuthService.csrfCookie()
    this.csrfCookieFetched = true
  }
}
