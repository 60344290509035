const en = {
  common: {
    or: 'or',
    cancel: 'Cancel',
    reset: 'Reset',
    save: 'Save',
    search: 'Search',
    edit: 'Edit',
    remove: 'Remove',
    new: 'New',
    export: 'Export to Excel',
    noDataToExport: 'No data to export',
    import: 'Import',
    discard: 'Discard',
    yes: 'Yes',
    no: 'No',
    pause: 'Pause',
    areYouSure: 'Are you sure?',
    view: 'View',
    destroy: 'Delete',
    mustSelectARow: 'Must select a row',
    confirm: 'Confirm',
    filters: 'Filters'
  },

  module: {
    person: {
      name: 'Person'
    },
    company: {
      name: 'Company'
    },
    deal: {
      name: 'Deal',
      status: {
        opportunity: '🧲 Opportunity',
        qualified: '✔️ Qualified',
        proposal: '📄 Proposal',
        negotiation: '🤝 Negotiation',
        invoiced: '🧾 Invoiced',
        won: '🎉 Won',
        lost: '🗑️ Lost'
      }
    },
    post: {
      name: 'Post',
      status: {
        idea: '💡 Idea',
        progress: '⏳ In progress',
        review: '🔍 In review',
        approved: '✅ Approved',
        published: '🚀 Published'
      }
    },
    activity: {
      type: {
        meeting: '📅 Meeting',
        call: '📞 Phone call',
        email_received: '📥 Email received',
        email_sent: '📤 Email sent'
      }
    },
    auth: {
      role: {
        viewer: 'Viewer',
        editor: 'Editor',
        admin: 'Admin'
      }
    }
  },

  platform: {
    facebook: {
      label: 'Facebook',
      post_type: {
        post: 'Post',
        shared_story: 'Post'
      }
    },
    instagram: {
      label: 'Instagram',
      post_type: {
        post: 'Post',
        image: 'Image',
        carousel: 'Carousel',
        reels: 'Reels',
        story: 'Story',
        video: 'Video'
      }
    },
    threads: {
      label: 'Threads',
      post_type: {
        post: 'Post'
      }
    },
    youtube: {
      label: 'YouTube',
      post_type: {
        post: 'Post'
      }
    },
    linkedin: {
      label: 'LinkedIn',
      post_type: {
        post: 'Post'
      }
    },
    tiktok: {
      label: 'TikTok',
      post_type: {
        post: 'Post'
      }
    },
    twitter: {
      label: 'Twitter',
      post_type: {
        post: 'Post'
      }
    },
    spotify: {
      label: 'Spotify',
      post_type: {
        post: 'Post'
      }
    },
    substack: {
      label: 'Substack',
      post_type: {
        post: 'Post'
      }
    }
  },

  shared: {
    filter: {
      text: {
        $eq: 'is',
        $ne: 'is not',
        $contains: 'contains',
        $notContains: 'does not contain',
        $startsWith: 'starts with',
        $endsWith: 'ends with',
        $null: 'is empty',
        $notNull: 'is not empty'
      },
      number: {
        $eq: 'is',
        $ne: 'is not',
        $lt: 'less than',
        $lte: 'less than or equal to',
        $gt: 'greater than',
        $gte: 'greater than or equal to',
        $between: 'between',
        $null: 'is empty',
        $notNull: 'is not empty'
      },
      date: {
        $eq: 'is',
        $lt: 'is before',
        $lte: 'is on or before',
        $gt: 'is after',
        $gte: 'is on or after',
        $between: 'between',
        $null: 'is empty',
        $notNull: 'is not empty'
      },
      datetime: {
        $eq: 'is',
        $lt: 'is before',
        $lte: 'is on or before',
        $gt: 'is after',
        $gte: 'is on or after',
        $between: 'between',
        $null: 'is empty',
        $notNull: 'is not empty'
      },
      enum: {
        $eq: 'is',
        $ne: 'is not',
        $in: 'includes',
        $notIn: 'does not include',
        $null: 'is empty',
        $notNull: 'is not empty'
      },
      'post-type': {
        $eq: 'is',
        $ne: 'is not',
        $in: 'includes',
        $notIn: 'does not include',
        $null: 'is empty',
        $notNull: 'is not empty'
      },
      entity: {
        $eq: 'is',
        $ne: 'is not',
        $in: 'includes',
        $notIn: 'does not include',
        $null: 'is empty',
        $notNull: 'is not empty'
      },
      boolean: {
        $null: 'is false',
        $notNull: 'is true'
      },
      tags: {
        $in: 'include',
        $notIn: 'do not include',
        $null: 'are empty',
        $notNull: 'are not empty'
      }
    }
  }
}

export default en
