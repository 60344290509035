import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

import authAxios from '@/shared/axios/auth-axios'

window.Pusher = Pusher

export default {
  install: (app, options) => {
    const echo = new Echo({
      authorizer: (channel, options) => {
        return {
          authorize: (socketId, callback) => {
            authAxios
              .post('/broadcasting/auth', {
                socket_id: socketId,
                channel_name: channel.name
              })
              .then((response) => {
                callback(false, response.data)
                authAxios.defaults.headers.common['X-Socket-Id'] = socketId
              })
              .catch((error) => {
                callback(true, error.response)
              })
          }
        }
      },
      broadcaster: 'reverb',
      key: import.meta.env.VITE_REVERB_APP_KEY,
      wsHost: import.meta.env.VITE_REVERB_HOST,
      wsPort: import.meta.env.VITE_REVERB_PORT,
      wssPort: import.meta.env.VITE_REVERB_PORT,
      forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
      enabledTransports: ['ws', 'wss']
    })

    app.config.globalProperties.$echo = echo
    app.provide('echo', echo)
  }
}
