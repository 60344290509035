import * as yup from 'yup'
import { i18nTranslate } from '@/i18n'
import GenericField from '@/shared/fields/generic-field'
import { format } from 'date-fns'
import FilterOperators from '@/enums/FilterOperators'

export default class DateField extends GenericField {
  constructor(name, label, config = {}) {
    super(name, label, config)

    this.required = config.required
    this.placeholder = config.placeholder
    this.hint = config.hint
    this.filterable = config.filterable || false
    this.sortable = config.sortable || false
    this.custom = config.custom
  }

  buildFilterQuery(attribute, operator, value) {
    const isNullOperator = [FilterOperators.NULL, FilterOperators.NOT_NULL].includes(operator)
    return {
      [attribute]: {
        [operator]: value ?? isNullOperator
      }
    }
  }

  forFilter() {
    return {
      name: this.name,
      label: this.label,
      custom: this.custom,
      props: {},
      defaultValue: null,
      value: null,
      defaultOperator: FilterOperators.EQUALS,
      operator: FilterOperators.EQUALS,
      type: 'date',
      availableOperators: [
        FilterOperators.EQUALS,
        FilterOperators.NULL,
        FilterOperators.NOT_NULL,
        FilterOperators.LESS_THAN,
        FilterOperators.LESS_THAN_OR_EQUAL_TO,
        FilterOperators.GREATER_THAN,
        FilterOperators.GREATER_THAN_OR_EQUAL_TO,
        FilterOperators.BETWEEN
      ],
      buildFilterQuery: this.buildFilterQuery
    }
  }

  forSort() {
    return {
      name: this.name,
      label: this.label
    }
  }

  forPresenter(value) {
    return value
  }

  forFilterPreview(value) {
    return value ? format(value, 'dd MMM yyyy') : null
  }

  forImportViewTable(value) {
    return value ? format(value, 'dd MMM yyyy') : null
  }

  forFilterCast() {
    return yup
      .mixed()
      .nullable(true)
      .label(this.label)
      .transform((value) => (value ? format(value, 'dd MMM yyyy') : null))
  }

  forFormRules() {
    const output = []

    if (this.required) {
      output.push({
        required: true,
        message: i18nTranslate('validation.mixed.required').replace('{path}', this.label)
      })
    }

    return output
  }

  forFormInitialValue(value) {
    return value ? moment(value, 'YYYY-MM-DD') : null
  }

  forFormCast() {
    return yup
      .mixed()
      .nullable(true)
      .label(this.label)
      .transform((value) => (value ? format(value, 'dd MMM yyyy') : null))
  }

  forExport() {
    return yup.mixed().label(this.label)
  }

  forImport() {
    let yupChain = yup
      .mixed()
      .nullable(true)
      .label(this.label)
      .test('is-date', i18nTranslate('validation.mixed.default'), (value) => {
        if (!value) {
          return true
        }

        return moment(value, 'YYYY-MM-DD').isValid()
      })
      .transform((value) => (value ? format(value, 'dd MMM yyyy') : null))

    if (this.required) {
      yupChain = yupChain.required()
    }

    return yupChain
  }
}
