import * as yup from 'yup'
import GenericField from '@/shared/fields/generic-field'
import { i18nTranslate } from '@/i18n'
import FilterOperators from '@/enums/FilterOperators'
import { isArray } from 'lodash'

export default class RelationToOneField extends GenericField {
  constructor(name, label, fetchFn, mapperFn, config = {}) {
    super(name, label, config)

    this.placeholder = config.placeholder
    this.hint = config.hint
    this.required = config.required
    this.fetchFn = fetchFn
    this.mapperFn = mapperFn
    this.filterable = config.filterable || false
    this.sortable = config.sortable || false
    this.custom = config.custom
  }

  buildFilterQuery(attribute, operator, value) {
    return {
      [attribute]: {
        id: {
          [operator]: isArray(value) ? value.map((i) => i.id) : value?.id
        }
      }
    }
  }

  forPresenter(value) {
    return value
  }

  forFilter() {
    return {
      name: this.name,
      label: this.label,
      custom: this.custom,
      props: {
        fetchFn: this.fetchFn,
        async: true
      },
      defaultValue: null,
      value: null,
      defaultOperator: FilterOperators.EQUALS,
      operator: FilterOperators.EQUALS,
      type: 'entity',
      availableOperators: [
        FilterOperators.EQUALS,
        FilterOperators.NOT_EQUALS,
        FilterOperators.IN,
        FilterOperators.NOT_IN,
        FilterOperators.NULL,
        FilterOperators.NOT_NULL
      ],
      buildFilterQuery: this.buildFilterQuery
    }
  }

  forSort() {
    return {
      name: `${this.name}.name`,
      label: this.label
    }
  }

  forFilterPreview(value) {
    return (value && value.label) || null
  }

  forFormRules() {
    const output = []

    if (this.required) {
      output.push({
        required: true,
        message: i18nTranslate('validation.mixed.required').replace('{path}', this.label)
      })
    }

    return output
  }

  forFormInitialValue(value) {
    return this.mapperFn(value)
  }

  forFilterInitialValue(value) {
    return this.mapperFn(value)
  }

  forFormCast() {
    return yup
      .mixed()
      .nullable(true)
      .label(this.label)
      .transform((value, originalValue) => {
        if (!originalValue) {
          return null
        }

        return originalValue.id
      })
  }

  forFilterCast() {
    return yup
      .mixed()
      .label(this.label)
      .transform((value, originalValue) => {
        if (!originalValue) {
          return null
        }

        return originalValue.id
      })
  }

  forExport() {
    return yup
      .mixed()
      .label(this.label)
      .transform((value, originalValue) => {
        if (!originalValue || !originalValue.id) {
          return null
        }

        return originalValue.id
      })
  }

  forImport() {
    let yupChain = yup.mixed().nullable(true).label(this.label)

    if (this.required) {
      yupChain = yupChain.required()
    }

    return yupChain
  }
}
