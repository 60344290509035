<template>
  <div class="flex items-center justify-between mb-2">
    <p :class="$style.boardColumnHeader">{{ column.name }}</p>
    <p :class="$style.boardColumnValue">
      {{ computedValue }}
    </p>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  column: {
    type: Object,
    default: () => {}
  },
  showSum: {
    type: Boolean,
    default: false
  }
})

const computedValue = computed(() => {
  return props.showSum ? computedSum.value : computedCount.value
})

const computedCount = computed(() => props.column.items.length)

const computedSum = computed(() => {
  const sum = props.column.items.reduce((acc, item) => {
    acc += Number(item.value)
    return acc
  }, 0)

  return new Intl.NumberFormat('pt-PT', {
    style: 'currency',
    currency: 'EUR'
  }).format(sum)
})
</script>

<style lang="scss" module>
.boardColumn {
  @apply bg-gray-5 w-full max-w-xs flex flex-col shrink-0 mx-2.5 p-4 rounded-lg;
}

.boardColumnHeader {
  @apply font-medium text-sm text-gray-100;
}

.boardColumnValue {
  @apply text-sm bg-gray-15 text-gray-100 px-2 py-1 rounded-lg ml-2;
}
</style>
