export default [
  {
    name: 'person-list',
    path: 'people',
    component: () => import('@/modules/person/pages/PersonListPage.vue'),
    meta: { auth: true, emailVerified: true, tenant: true, init: true }
  },
  {
    name: 'person-view',
    path: 'people/:id',
    component: () => import('@/modules/person/pages/PersonViewPage.vue'),
    meta: { auth: true, emailVerified: true, tenant: true, init: true },
    props: true
  }
]
