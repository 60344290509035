import TextField from '@/shared/fields/text-field'
import NumberField from '@/shared/fields/number-field'
import EnumeratorField from '@/shared/fields/enumerator-field'
import DealStages from '@/enums/DealStages'
import { i18nTranslate } from '@/i18n'
import RelationToOneField from '@/shared/fields/relation-to-one-field'
import CompanyService from '@/modules/company/company-service'
import TagsField from '@/shared/fields/tags-field'
import BrandService from '@/modules/brand/brand-service'

const fields = {
  name: new TextField('name', 'Name', {
    sortable: true,
    filterable: true
  }),
  description: new TextField('description', 'Description', {
    filterable: true
  }),
  value: new NumberField('value', 'Value', {
    sortable: true,
    filterable: true,
    props: { currency: 'EUR', mode: 'currency' }
  }),
  status: new EnumeratorField(
    'status',
    'Status',
    Object.values(DealStages).map((s) => {
      return {
        id: s,
        name: i18nTranslate(`module.deal.status.${s}`)
      }
    }),
    { sortable: true, filterable: true }
  ),
  brand: new RelationToOneField('brand', 'Brand', BrandService.list, (v) => v, {
    sortable: true,
    filterable: true
  }),
  company: new RelationToOneField('company', 'Company', CompanyService.list, (v) => v, {
    sortable: true,
    filterable: true
  }),
  tags: new TagsField('tags', 'Tags', { filterable: true })
}

export default class DealModel {
  static get fields() {
    return fields
  }
}
