export default [
  {
    name: 'auth',
    path: '/auth',
    component: () => import('@/modules/auth/layouts/AuthLayout.vue'),
    children: [
      {
        name: 'signup',
        path: 'signup',
        component: () => import('@/modules/auth/pages/SignupPage.vue'),
        meta: { unauth: true }
      },
      {
        name: 'login',
        path: 'login',
        component: () => import('@/modules/auth/pages/LoginPage.vue'),
        meta: { unauth: true }
      },
      {
        name: 'logout',
        path: 'logout',
        component: () => import('@/modules/auth/pages/LogoutPage.vue'),
        meta: { auth: true }
      },
      {
        name: 'forgot-password',
        path: 'forgot-password',
        component: () => import('@/modules/auth/pages/ForgotPasswordPage.vue'),
        meta: { unauth: true }
      },
      {
        name: 'password-reset',
        path: 'password-reset',
        component: () => import('@/modules/auth/pages/PasswordResetPage.vue')
      },
      {
        name: 'email-unverified',
        path: 'email-unverified/:email',
        component: () => import('@/modules/auth/pages/EmailUnverifiedPage.vue'),
        meta: { auth: true },
        props: true
      },
      {
        name: 'verify-email',
        path: 'verify-email/:email',
        component: () => import('@/modules/auth/pages/VerifyEmailPage.vue'),
        props: true
      },
      {
        name: 'invite',
        path: 'invite/:inviteId',
        component: () => import('@/modules/auth/pages/InvitePage.vue'),
        props: true
      },
      {
        name: 'new-workspace',
        path: 'new-workspace',
        component: () => import('@/modules/auth/pages/NewWorkspace.vue'),
        meta: { auth: true, emailVerified: true }
      },
      {
        name: 'select-workspace',
        path: 'select-workspace',
        component: () => import('@/modules/auth/pages/SelectWorkspace.vue'),
        meta: { auth: true, emailVerified: true }
      }
    ]
  },
  // Putting this here because of the default layout on the modules folder
  {
    name: 'notification',
    path: '/tenant/:tenantId/notification/:notificationId',
    component: () => import('@/modules/auth/pages/NotificationPage.vue'),
    meta: { auth: true, emailVerified: true },
    props: true
  }
]
