import sharedGetters from '@/shared/store/getters'

export default {
  ...sharedGetters(),
  moduleViews: (state) => {
    return (module) => Object.values(state.records).filter((v) => v.module === module)
  },
  moduleActiveView: (state) => {
    return (module) => {
      const moduleViews = Object.values(state.records).filter((v) => v.module === module)
      return moduleViews.find((v) => v.active) ?? moduleViews[0]
    }
  }
}
