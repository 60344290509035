import TextField from '@/shared/fields/text-field'
import TagsField from '@/shared/fields/tags-field'
import BooleanField from '@/shared/fields/boolean-field'
import RelationToManyField from '@/shared/fields/relation-to-many-field'
import UserService from '@/modules/user/user-service'
import DateField from '@/shared/fields/date-field'

const fields = {
  name: new TextField('name', 'Name', {
    filterable: true
  }),
  description: new TextField('description', 'Description', {
    filterable: true
  }),
  completed_at: new BooleanField('completed_at', 'Done', {
    filterable: true
  }),
  due_at: new DateField('due_at', 'Due date', {
    filterable: true,
    sortable: true
  }),
  users: new RelationToManyField('users', 'Users', UserService.list, (v) => v, {
    filterable: true
  }),
  tags: new TagsField('tags', 'Tags', { filterable: true })
}

export default class TaskModel {
  static get fields() {
    return fields
  }
}
