import ViewService from '@/modules/view/view-service'
import Errors from '@/shared/error/errors'
import { cloneDeep } from 'lodash'
import modules from '@/modules'

const parseRecord = (record) => {
  const settings = JSON.parse(record.settings) ?? {
    filter: { attributes: {}, conjunction: '$and' },
    sort: [],
    date: {
      name: modules[record.module].model.defaultDateName,
      label: modules[record.module].model.defaultDateLabel
    }
  }

  return {
    ...record,
    settings,
    filter: cloneDeep(settings.filter),
    sort: cloneDeep(settings.sort),
    date: settings.date,
    paginator:
      record.type === 'table'
        ? {
            first: 0,
            perPage: 25
          }
        : undefined
  }
}

export default {
  async doFetch() {
    try {
      const { data, meta } = await ViewService.list()

      for (const record of data) {
        this.records[record.id] = parseRecord(record)

        if (!this.list.ids.includes(record.id)) {
          this.list.ids.push(record.id)
        }
      }
      this.count = meta?.total ?? data.length
    } catch (error) {
      Errors.handle(error)
    }
  },

  async doFind(id) {
    try {
      const { data: record } = await ViewService.find(id)

      this.records[id] = {
        ...this.records[id],
        ...parseRecord(record)
      }
      return record
    } catch (error) {
      Errors.handle(error)
    }
  },

  async doCreate(values) {
    try {
      const { data: record } = await ViewService.create(values)
      this.records[record.id] = parseRecord(record)
      this.count++
      return record
    } catch (error) {
      Errors.handle(error)
    }
  },

  async doUpdate(id, values) {
    try {
      const { data: record } = await ViewService.update(id, values)
      this.records[record.id] = {
        ...this.records[id],
        ...parseRecord(record)
      }
      return record
    } catch (error) {
      Errors.handle(error)
    }
  },

  async doDestroy(id) {
    try {
      await ViewService.destroy(id)
      delete this.records[id]
      this.count--
      this.list.ids = this.list.ids.filter((id) => id !== id)
    } catch (error) {
      Errors.handle(error)
    }
  },

  setView(view) {
    const originalView = this.records[view.id] ?? {}
    this.records[view.id] = {
      ...originalView,
      ...parseRecord(view)
    }
  },

  setModuleActiveView(module, viewId) {
    for (let view of this.moduleViews(module)) {
      view.active = false
    }
    this.records[viewId].active = true
  },

  setViewPaginatorFirst(viewId, first) {
    this.records[viewId].paginator.first = first
  },

  setViewPaginatorPerPage(viewId, perPage) {
    this.records[viewId].paginator.perPage = perPage
  }
}
